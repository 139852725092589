import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { SupportedGame } from '../../../modules/host/models';
import { hostsSliceActions } from '../../../modules/host/slice';
import { getMainUserState } from '../../../modules/main/selector';
import store from '../../../store/store';
import { getIsRestState } from '../../../utils/commonFunc';
import { convertResultToRecord } from '../../../utils/games/dragonTiger';
import { DragonTigerResult } from '../../games/dragonTiger';
import { GameState } from '../../games/enums/GameState';
import { GameType } from '../../games/enums/GameType';
import { TableMode } from '../../TableMode';
import { CMDScInitDragontiger, ICommand } from '../live';
import { CMDDragontiger } from '../live/CMDDragontiger';
import { AbstractHandler } from './AbstractHandler';

export class CMDScInitDragontigerHandler extends AbstractHandler {
    _command: CMDScInitDragontiger;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScInitDragontiger;
    }
    override convertResult(result: CMDDragontiger): DragonTigerResult {
        const newResult = Object.assign(
            this.convertBaseResult(result.BaseResult),
            {
                DragonCard: result.DragonCard - 1,
                TigerCard: result.TigerCard - 1,
            }
        ) as DragonTigerResult;
        return newResult;
    }
    override handle(dispatch: Dispatch<AnyAction>): void {
        const state = store.getState();
        const { IsInternalTest } = getMainUserState(state);
        const game = {} as SupportedGame;
        const info = this.convertInformation(this._command.Information);
        const hostId = info.HostId;
        game.CurrentState = this._command.CurrentState;
        game.CurrentResult = this.convertResult(this._command.CurrentResult);
        game.OldResult = this._command.OldResult.filter(o => {
            if (o.BaseResult.Result === 0) return false;
            return true;
        }).map(o => this.convertResult(o));
        game.OldResult = game.OldResult.filter(
            (arr, index, self) =>
                index === self.findIndex(t => t.GameID === arr.GameID)
        );
        game.ResultReleased = false;
        game.IsRest = getIsRestState(
            this._command.Information.Rest,
            IsInternalTest
        );
        game.IsInternalTest =
            IsInternalTest &&
            this._command.Information.Rest == TableMode.InternalTest;

        const convertedRecord =
            game.CurrentState === GameState.Shuffle
                ? []
                : game.OldResult.map(r =>
                      convertResultToRecord(r as DragonTigerResult)
                  );

        // const state = store.getState();
        // const host = getHostById(state, hostId);
        // if (host) {
        //     info.CountDown -= host.DelayResult;
        // }

        batch(() => {
            dispatch(hostsSliceActions.gameInit({ hostId, game }));
            dispatch(hostsSliceActions.updateInformation(info));
            dispatch(
                hostsSliceActions.updateRecord({
                    hostId,
                    record: convertedRecord,
                    gameType: GameType.Dragon,
                })
            );
        });
    }
}
