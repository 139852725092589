import { CMDBetSummary } from "./CMDBetSummary"
import { CMDTotalBet } from "./CMDTotalBet"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScTotalBetCarnivaltreasure implements ICommand
{

	public Total : CMDTotalBet;
	public Main1 : CMDBetSummary;
	public Main2 : CMDBetSummary;
	public Main4 : CMDBetSummary;
	public Main7 : CMDBetSummary;
	public Main18 : CMDBetSummary;
	public Main40 : CMDBetSummary;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Total = new CMDTotalBet();
		this.Total.fromBytes(ba);
		this.Main1 = new CMDBetSummary();
		this.Main1.fromBytes(ba);
		this.Main2 = new CMDBetSummary();
		this.Main2.fromBytes(ba);
		this.Main4 = new CMDBetSummary();
		this.Main4.fromBytes(ba);
		this.Main7 = new CMDBetSummary();
		this.Main7.fromBytes(ba);
		this.Main18 = new CMDBetSummary();
		this.Main18.fromBytes(ba);
		this.Main40 = new CMDBetSummary();
		this.Main40.fromBytes(ba);
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		let tempBuf0 = this.Total.toBytes().toBuffer();
		ba.appendBuf(tempBuf0)
		let tempBuf1 = this.Main1.toBytes().toBuffer();
		ba.appendBuf(tempBuf1)
		let tempBuf2 = this.Main2.toBytes().toBuffer();
		ba.appendBuf(tempBuf2)
		let tempBuf3 = this.Main4.toBytes().toBuffer();
		ba.appendBuf(tempBuf3)
		let tempBuf4 = this.Main7.toBytes().toBuffer();
		ba.appendBuf(tempBuf4)
		let tempBuf5 = this.Main18.toBytes().toBuffer();
		ba.appendBuf(tempBuf5)
		let tempBuf6 = this.Main40.toBytes().toBuffer();
		ba.appendBuf(tempBuf6)

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScTotalBetCarnivaltreasure();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Total(CMDTotalBet)='+this.Total+' ';
		str += 'Main1(CMDBetSummary)='+this.Main1+' ';
		str += 'Main2(CMDBetSummary)='+this.Main2+' ';
		str += 'Main4(CMDBetSummary)='+this.Main4+' ';
		str += 'Main7(CMDBetSummary)='+this.Main7+' ';
		str += 'Main18(CMDBetSummary)='+this.Main18+' ';
		str += 'Main40(CMDBetSummary)='+this.Main40+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 30757;
	}

	public getCmdName() : string
	{
		return 'ScTotalBetCarnivaltreasure';
	}

}
