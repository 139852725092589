import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDBetLogFail implements ICommand
{

	public UTCSecond : number = 0;
	public HostID : number = 0;
	public GameType : number = 0;
	public GameCount : number = 0;
	public BetType : number = 0;
	public BetAmount : number = 0;
	public GameID : number = 0;
	public Reason : string = "";

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.UTCSecond = ba.readInt53();
		this.HostID = ba.readUint16();
		this.GameType = ba.readInt53();
		this.GameCount = ba.readUint32();
		this.BetType = ba.readUint8();
		this.BetAmount = ba.readInt53();
		this.GameID = ba.readInt53();
		this.Reason = ba.readString();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint53(this.UTCSecond);
		ba.writeUint16(this.HostID);
		ba.writeUint53(this.GameType);
		ba.writeUint32(this.GameCount);
		ba.writeUint8(this.BetType);
		ba.writeUint53(this.BetAmount);
		ba.writeUint53(this.GameID);
		ba.writeString(this.Reason);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDBetLogFail();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'UTCSecond(Q)='+this.UTCSecond+' ';
		str += 'HostID(W)='+this.HostID+' ';
		str += 'GameType(Q)='+this.GameType+' ';
		str += 'GameCount(D)='+this.GameCount+' ';
		str += 'BetType(B)='+this.BetType+' ';
		str += 'BetAmount(Q)='+this.BetAmount+' ';
		str += 'GameID(Q)='+this.GameID+' ';
		str += 'Reason(S)='+this.Reason+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'BetLogFail';
	}

}
