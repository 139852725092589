import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { hostsSliceActions } from '../../../modules/host/slice';
import { getMainUserState } from '../../../modules/main/selector';
import store from '../../../store/store';
import { CMDScGameRest, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDScGameRestHandler extends AbstractHandler {
    _command: CMDScGameRest;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScGameRest;
    }

    override handle(dispatch: Dispatch<AnyAction>): void {
        const state = store.getState();
        const { IsInternalTest } = getMainUserState(state);
        dispatch(
            hostsSliceActions.updateGameRest({
                hostId: this._command.HostID,
                onOrOff: this._command.OnOrOff,
                isTest: IsInternalTest,
            })
        );
    }
}
