import { AppBar, Box } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
// import BaseImage from '../../components/common/baseImage';
import { BetChipProvider } from '../../contexts/BetChipContext';
import { CommonConfigContext } from '../../contexts/ConfigContext';
import { EnterCountContext } from '../../contexts/EnterCountContext';
import { VideoPlayerProvider } from '../../contexts/VideoContext';
import { WebSocketContext } from '../../contexts/WebSocketContext';
import { useLocalStorage } from '../../hooks/storage/useLocalStorage';
import { MessageCommand, sendToParent } from '../../hooks/useIframeMessage';
import { useVideoZoom } from '../../hooks/useVideoZoom';
import { ROUTE_LOBBY, ROUTE_THANKS4PLAY } from '../../models/Route';
import {
    CMDPsRoomSeat,
    CMDPsRuleChange,
    CMDPsTableEnter,
} from '../../models/cmd/live';
import { GameState } from '../../models/games/enums/GameState';
import { GameType as GameTypeEnum } from '../../models/games/enums/GameType';
import { BetType as PokDengBetType } from '../../models/games/pokdeng/BetType';
import { Bet } from '../../models/host/BetAmount';
import { GameCategoryTab } from '../../models/lobby/GameCategoryTab';
import { RootState } from '../../store/store';
import { isNewGameType } from '../../utils/commonFunc';
import { InGameCountdown } from '../countdown';
import { getGameSate } from '../games/selector';
import { gameSliceActions } from '../games/slice';
import { getHostById } from '../host/slice';
import {
    getLandingState,
    getMainLimitRedState,
    getMainUserState,
} from '../main/selector';
import { inGamePopupSliceActions } from '../popup/inGameSlice';
import { popupSliceActions } from '../popup/slice';
import { routeSliceActions } from '../routeController/slice';
import { VideoPlayer } from '../videoPlayer';
import VideoImages from './VideoImages';
import { BetAreaPanelContainer } from './components/BetAreaPanels';
import BetResultPanel from './components/BetResult/BetResultPanel';
import Footer from './components/Footer';
import GameResultPanel from './components/GameResultPopup/GameResultPanel';
import { PGameHeader } from './components/Header';
import { InGamePopupLayer } from './components/InGamePopupLayer';
import { TableInfoHeader } from './components/TableInfoHeader';
import { TableInfoPanel } from './components/TableInfoPanel';
import { VideoUnmutePanel } from './components/VideoUnmutePanel';

const KICK_PLAYER_IDLE_COUNT: number = 10;
export const GameScene = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const hostId = Number(params.hostId);
    const host = useSelector((state: RootState) => getHostById(state, hostId));
    const { lite } = useSelector(getLandingState);
    const { notToReconnect } = useSelector(getGameSate);
    const { IsDemo } = useSelector(getMainUserState);
    const [noBetCount, setNoBetCount] = useState(0);
    const [videoBg, setVideoBg] = useState<string>('');
    const [haveZoom, setHaveZoom] = useState(false);
    const [haveInfo, setHaveInfo] = useState(false);
    const [haveGameRule, setHaveGameRule] = useState(false);
    const [haveSwitchTable, setHaveSwitchTable] = useState(true);
    const [showCountdown, setShowCountdown] = useState(false);
    const [currentTab, setCurrentTab] = useLocalStorage(
        'lobby_ui_tab',
        GameCategoryTab.Baccarat
    );
    const [hostInitTimeout, setHostInitTimeout] = useState(false);

    const isNewGame = useMemo(() => {
        if (!host) {
            return false;
        } else {
            localStorage.setItem(
                'lobby_ui_tab',
                GameCategoryTab.OtherGames.toString()
            );
            return isNewGameType(host.GameType);
        }
    }, [host.GameType]);
    const isVideoZoom = useVideoZoom(hostId);
    const [showVideoButtonBackground, setShowVideoButtonBackground] =
        useState(true);
    const limitRedByGameType = useSelector(getMainLimitRedState);
    const { sendCommand } = useContext(WebSocketContext);
    const { addCount } = useContext(EnterCountContext);
    const { hideSwitchTable } = useContext(CommonConfigContext);
    const onSeat = () => {
        const { GameType } = host;
        let cmd;
        // TODO: Handle different game type later
        switch (GameType) {
            case GameTypeEnum.Baccarat:
            case GameTypeEnum.Blackjack:
                cmd = new CMDPsRoomSeat();
                cmd.HostID = Number(hostId);
                cmd.Seat = 100;
                cmd.IsMultiBet = 0;
                break;
            default:
                cmd = new CMDPsTableEnter();
                cmd.HostID = Number(hostId);
                break;
        }
        sendCommand(cmd);
    };
    const sendRuleCommand = (gt: number, hId: number, idx: number) => {
        const cmd = new CMDPsRuleChange();
        cmd.GameType = gt;
        cmd.HostID = hId;
        cmd.RuleIndex = idx;
        sendCommand(cmd);
    };
    const onGetMaxBet = () => {
        //TODO: 如果有問題，改就收到'CMDSpLimitRedUpdate', 後面pre send;
        const { MaxBet, HostId, GameType } = host;
        if (limitRedByGameType.BetRuleByGameType) {
            const limitRedByGame = limitRedByGameType.BetRuleByGameType?.find(
                br => br.GameType === GameType
            );
            if (limitRedByGame) {
                const BetRule = limitRedByGame.BetRule;
                if (limitRedByGame.RuleToSelect) {
                    sendRuleCommand(
                        GameType,
                        HostId,
                        limitRedByGame.RuleToSelect.RuleID
                    );
                } else if (BetRule && !MaxBet) {
                    //const l = BetRule.concat(BetRule);
                    let selBetRule = BetRule.find(r => r.Selected === 1);
                    if (!selBetRule) selBetRule = BetRule[0]; //有機會全部selected=0
                    if (selBetRule) {
                        sendRuleCommand(GameType, HostId, selBetRule.RuleID);
                    }
                }
            } else {
                dispatch(routeSliceActions.goto(ROUTE_LOBBY));
                dispatch(popupSliceActions.open('system.error_m226.17'));
            }
        }
    };
    const getBetWithHold = () => {
        const { GameType } = host;

        let withHold = new Array<Bet>();
        switch (GameType) {
            case GameTypeEnum.PokDeng:
                withHold.push({
                    Type: PokDengBetType.PDBPlayer1Win,
                    Amount: 1,
                    GameId: 0,
                });
                withHold.push({
                    Type: PokDengBetType.PDBPlayer2Win,
                    Amount: 1,
                    GameId: 0,
                });
                withHold.push({
                    Type: PokDengBetType.PDBPlayer3Win,
                    Amount: 1,
                    GameId: 0,
                });
                withHold.push({
                    Type: PokDengBetType.PDBPlayer4Win,
                    Amount: 1,
                    GameId: 0,
                });
                withHold.push({
                    Type: PokDengBetType.PDBPlayer5Win,
                    Amount: 1,
                    GameId: 0,
                });
        }
        return withHold.length === 0 ? undefined : withHold;
    };
    const updateCurrentBetState = () => {
        if (host.CurrentResult) {
            let currentBets = host.ConfirmedBets ? host.ConfirmedBets : [];
            if (
                currentBets.length > 0 &&
                currentBets[0].GameId != host.CurrentResult.GameID
            ) {
                currentBets = [];
            }
            dispatch(
                gameSliceActions.setCurrentGame({
                    gameId: host.CurrentResult.GameID,
                    bets: currentBets,
                    withHold: getBetWithHold(),
                })
            );
        }
    };
    const getVideoImageUrl = (
        gameType: GameTypeEnum,
        hall: string,
        zoom?: boolean
    ) => {
        let imgUrl = '';
        switch (gameType) {
            case GameTypeEnum.AndarBahar:
                imgUrl = `bg_andar_bahar_${hall}table`;
                break;
            case GameTypeEnum.Baccarat:
                imgUrl = `bg_baccarat_${hall}table`;
                break;
            case GameTypeEnum.Blackjack:
                imgUrl = `bg_blackjack_${hall}table`;
                if (zoom) {
                    imgUrl += '_zoom';
                }
                break;
            case GameTypeEnum.Dragon:
                imgUrl = `bg_dragontiger_${hall}table`;
                break;
            case GameTypeEnum.PokDeng:
                imgUrl = `bg_pokdeng_${hall}table`;
                break;
            case GameTypeEnum.Roulette:
                imgUrl = `bg_roulette_${hall}table`;
                break;
            case GameTypeEnum.SicBo:
                imgUrl = `bg_sicbo_${hall}table`;
                break;
            case GameTypeEnum.TeenPatti2020:
                imgUrl = `bg_teen_patti_${hall}table`;
                break;
            case GameTypeEnum.SeDie:
                imgUrl = `bg_sedie_${hall}table`;
                break;
        }
        return imgUrl;
    };
    const noticeRest = () => {
        // when using backdoor to enter rest table
        sendToParent({
            type: MessageCommand.HOST_REST,
            data: { hostId: host.HostId },
        });
        addCount();
    };
    const noticeUnknownTable = () => {
        // when using backdoor to enter unknown table
        sendToParent({
            type: MessageCommand.HOST_UNKNOWN,
            data: { hostId: host.HostId },
        });
    };
    useEffect(() => {
        if (host) {
            const { GameType, Group } = host;
            if (GameType == GameTypeEnum.Blackjack) {
                setVideoBg(getVideoImageUrl(GameType, Group[0], isVideoZoom));
            }
        }
    }, [isVideoZoom]); //switch table
    useEffect(() => {
        if (host?.GameType) {
            const { GameType, Group } = host;
            let gameLobbyTab = currentTab;
            let isZoom = false;
            switch (GameType) {
                case GameTypeEnum.Baccarat:
                    setHaveZoom(false);
                    setHaveInfo(false);
                    setHaveGameRule(false);
                    gameLobbyTab = GameCategoryTab.Baccarat;
                    break;
                case GameTypeEnum.Dragon:
                    setHaveZoom(false);
                    setHaveInfo(false);
                    setHaveGameRule(false);
                    gameLobbyTab = GameCategoryTab.OtherGames;
                    break;
                case GameTypeEnum.PokDeng:
                    setHaveGameRule(true);
                    setHaveZoom(false);
                    setHaveInfo(false);
                    gameLobbyTab = GameCategoryTab.OtherGames;
                    break;
                case GameTypeEnum.SeDie:
                    setHaveZoom(false);
                    setHaveInfo(false);
                    setHaveGameRule(false);
                    gameLobbyTab = GameCategoryTab.OtherGames;
                    break;
                case GameTypeEnum.AndarBahar:
                    setHaveInfo(true);
                    setHaveZoom(false);
                    setHaveGameRule(false);
                    gameLobbyTab = GameCategoryTab.OtherGames;
                    break;
                case GameTypeEnum.TeenPatti2020:
                    setHaveInfo(true);
                    setHaveZoom(false);
                    setHaveGameRule(false);
                    gameLobbyTab = GameCategoryTab.OtherGames;
                    break;
                case GameTypeEnum.Blackjack:
                    setHaveZoom(false);
                    setShowVideoButtonBackground(false);
                    setHaveInfo(false);
                    setHaveGameRule(false);
                    isZoom = isVideoZoom;
                    gameLobbyTab = GameCategoryTab.OtherGames;
                    break;
                case GameTypeEnum.SicBo:
                    setHaveZoom(false);
                    setHaveInfo(false);
                    setHaveGameRule(false);
                    gameLobbyTab = GameCategoryTab.OtherGames;
                    break;
                case GameTypeEnum.Roulette:
                    gameLobbyTab = GameCategoryTab.OtherGames;
                    break;
                default:
                    setHaveInfo(false);
                    setHaveGameRule(false);
                    break;
            }
            setVideoBg(getVideoImageUrl(GameType, Group[0], isZoom));
            onSeat();
            onGetMaxBet();
            updateCurrentBetState();
            dispatch(inGamePopupSliceActions.close());
            //default rebet & double button
            dispatch(gameSliceActions.resetAll());

            if (gameLobbyTab != currentTab) {
                setCurrentTab(gameLobbyTab);
            }

            // prevent change table too fast
            dispatch(gameSliceActions.setInitBlock({ block: true }));
            setTimeout(() => {
                dispatch(gameSliceActions.setInitBlock({ block: false }));
            }, 2000);
        } else {
            noticeUnknownTable();
            dispatch(routeSliceActions.goto(ROUTE_LOBBY));
        }
        setNoBetCount(0);
    }, [hostId]); //switch table
    useEffect(() => {
        updateCurrentBetState();
    }, [host.CurrentResult]);
    useEffect(() => {
        if (host.ConfirmedBets && host.ConfirmedBets.length > 0) {
            setNoBetCount(0);
        }
    }, [host.ConfirmedBets]);
    useEffect(() => {
        if (host.IsRest && host.CurrentResult) {
            //wait host
            noticeRest();
            dispatch(routeSliceActions.goto(ROUTE_LOBBY));
            dispatch(popupSliceActions.open('system.table_close'));
        }
        if (!host.CurrentResult) {
            setTimeout(() => {
                setHostInitTimeout(true);
            }, 3000);
        }
    }, [host.IsRest, host.CurrentResult]);
    useEffect(() => {
        if (hostInitTimeout) {
            if (host.IsRest) {
                //wait host
                noticeRest();
                dispatch(routeSliceActions.goto(ROUTE_LOBBY));
                dispatch(popupSliceActions.open('system.table_close'));
            }
        }
    }, [hostInitTimeout]);
    useEffect(() => {
        if (noBetCount >= KICK_PLAYER_IDLE_COUNT + 1) {
            dispatch(routeSliceActions.goto(ROUTE_LOBBY));
            dispatch(popupSliceActions.open('system.idle_kick'));
        }
    }, [noBetCount]);
    useEffect(() => {
        const isBetting = host.CurrentState === GameState.Betting;
        setShowCountdown(isBetting);
        if (isBetting && !IsDemo) {
            setNoBetCount(noBetCount + 1);
        }
    }, [host.CurrentState]);
    useEffect(() => {
        if (hideSwitchTable) {
            setHaveSwitchTable(false);
        } else setHaveSwitchTable(!lite);
        if (notToReconnect) {
            //For handle back button
            dispatch(routeSliceActions.goto(ROUTE_THANKS4PLAY));
        }
    }, []);

    return host && !isNewGame ? (
        <VideoPlayerProvider hostId={host.HostId}>
            <BetChipProvider gameType={host.GameType}>
                <>
                    <InGamePopupLayer />
                    <GameResultPanel />
                    <BetResultPanel />
                    <AppBar
                        component={'header'}
                        elevation={0}
                        sx={{ backgroundColor: 'rgba(255,255,255,0)' }}
                    >
                        <PGameHeader isMultiBet={false} />
                    </AppBar>

                    <Box
                        component={'main'}
                        sx={{
                            marginTop: '50px',
                            marginLeft: '0px',
                        }}
                    >
                        {showCountdown && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    left: '10px',
                                    top: '60px',
                                }}
                            >
                                <InGameCountdown hostId={hostId} />
                            </Box>
                        )}
                        <Box
                            id={'video-bg'}
                            sx={{
                                position: 'absolute',
                                top: '50px',
                                left: '0px',
                                zIndex: '-1',
                            }}
                        >
                            {/* <BaseImage
                                className={`videoBg ${videoBg}`}
                                scale={1}
                            /> */}
                            {videoBg && (
                                <img
                                    src={
                                        VideoImages[
                                            videoBg as keyof typeof VideoImages
                                        ]
                                    }
                                    alt=""
                                    width="540px"
                                />
                            )}
                        </Box>
                        <VideoPlayer />
                        <BetAreaPanelContainer
                            hostId={hostId}
                            gameType={host.GameType!}
                        />
                        <TableInfoHeader
                            haveZoom={haveZoom}
                            haveInfo={haveInfo}
                            haveGameRule={haveGameRule}
                            haveVideoButtonBackground={
                                showVideoButtonBackground
                            }
                            haveSwitchTable={haveSwitchTable}
                            haveTotalBet={true}
                        />
                        <TableInfoPanel
                            hostId={hostId}
                            gameType={host.GameType!}
                        />
                        <Footer />
                    </Box>
                    <VideoUnmutePanel />
                </>
            </BetChipProvider>
        </VideoPlayerProvider>
    ) : (
        <Navigate to={ROUTE_LOBBY} />
    );
};
