import { Box, Button } from '@mui/material';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { WebSocketContext } from '../../../../contexts/WebSocketContext';
import { getLandingState } from '../../../main/selector';
import { NewUIIcon } from './NewUIIcon';

export const NewUIFloating = () => {
    const { username } = useSelector(getLandingState);
    const { changeToNewUI } = useContext(WebSocketContext);
    return (
        <>
            <Button
                sx={{
                    padding: 0,
                    margin: 0,
                    position: 'fixed',
                    top: '770px',
                    right: 0,
                }}
                onClick={() => {
                    changeToNewUI(username, 'lobby');
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <NewUIIcon />
                </Box>
                {/* </Box> */}
            </Button>
        </>
    );
};
