import { Button } from '@mui/material';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BaseTypography } from '../../../../../../components/common/baseText/BaseTypography';
import { VideoPlayerContext } from '../../../../../../contexts/VideoContext';
import { useCurrentTime } from '../../../../../../hooks/useCurrentTime';
import {
    POPUP_CONFIRM_ACTION,
    POPUP_TYPE,
} from '../../../../../../models/Popup';
import { ROUTE_NEW_UI } from '../../../../../../models/Route';
import { getPopupState } from '../../../../../popup/selector';
import { popupSliceActions } from '../../../../../popup/slice';
import { routeSliceActions } from '../../../../../routeController/slice';

const startTime = 1744624800000;
export const BaccaratNewSideBet = () => {
    const { currentTimeStamp } = useCurrentTime();
    const { confirmValue } = useSelector(getPopupState);
    const { hostId } = useContext(VideoPlayerContext);
    const dispatch = useDispatch();
    const [languageDir, setLanguageDir] = useState<string>('ltr');
    const [showButton, setShowButton] = useState<boolean>(false);
    const { i18n } = useTranslation();
    useEffect(() => {
        setLanguageDir(i18n.dir(i18n.language));
    }, [i18n.language]);
    // const controller = useAnimationControls();
    // const anim = () => {
    //     controller.start({
    //         opacity: [0, 1, 0.5, 0],
    //         transition: { duration: 2 },
    //     });
    // };
    useEffect(() => {
        setShowButton(currentTimeStamp() >= startTime);
        // anim();
    }, []);
    useEffect(() => {
        if (confirmValue === POPUP_CONFIRM_ACTION.NEW_SIDE_BET) {
            dispatch(routeSliceActions.goto(`${ROUTE_NEW_UI}/${hostId}`));
        }
    }, [confirmValue]);
    const gotoNew = () => {
        // payload: string | Array<string>,
        // confirmFunc: POPUP_CONFIRM_ACTION = POPUP_CONFIRM_ACTION.NONE,
        // type: POPUP_TYPE = POPUP_TYPE.ALERT,
        // dismiss: number = 0,
        // leaveFromConfirm: boolean = false,
        // clearLoginDataWhenLeave: boolean = true,
        // params: Record<string, string> = {}
        dispatch(
            popupSliceActions.open(
                'new_ui.side_bet_confirm',
                POPUP_CONFIRM_ACTION.NEW_SIDE_BET,
                POPUP_TYPE.CONFIRM
            )
        );
        // dispatch(routeSliceActions.goto(`${ROUTE_NEW_UI}/${hostId}`));
    };
    return showButton ? (
        <Button
            onClick={gotoNew}
            sx={{
                left: '5px',
                padding: 'unset',
                width: 'auto',
                borderRadius: '15px',
                backgroundColor: '#997829',
                textTransform: 'unset',
            }}
        >
            <BaseTypography
                fontSize={14}
                className="bacc-new-sidebet-fade-bg"
                sx={{
                    width: '100%',
                    padding: '0px 10px',
                    borderRadius: '15px',
                    color: '#fff',
                    direction: languageDir,
                }}
            >
                {t('new_game.more')}
                {/* {t(`baccarat.${betPageList[currentTab]}_short`)} */}
                <div style={{ height: 16 }}>
                    <svg
                        height="100%"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M28.7733 21.4534L27.32 11.2401C26.9467 8.61342 24.6933 6.66675 22.04 6.66675H9.96C7.30667 6.66675 5.05334 8.61342 4.68001 11.2401L3.22667 21.4534C2.93334 23.5067 4.52001 25.3334 6.58667 25.3334C7.49334 25.3334 8.34667 24.9734 8.98667 24.3334L12 21.3334H20L23 24.3334C23.64 24.9734 24.5067 25.3334 25.4 25.3334C27.48 25.3334 29.0667 23.5067 28.7733 21.4534ZM25.9733 22.4134C25.8667 22.5334 25.6933 22.6667 25.4133 22.6667C25.2133 22.6667 25.0267 22.5867 24.8933 22.4534L21.1067 18.6667H10.8933L7.10667 22.4534C6.97334 22.5867 6.78667 22.6667 6.58667 22.6667C6.30667 22.6667 6.13334 22.5334 6.02667 22.4134C5.92001 22.2934 5.81334 22.1067 5.85334 21.8267L7.30667 11.6134C7.50667 10.3201 8.64001 9.33341 9.96 9.33341H22.04C23.36 9.33341 24.4933 10.3201 24.68 11.6267L26.1333 21.8401C26.1733 22.1067 26.0667 22.2934 25.9733 22.4134Z"
                            fill="white"
                        />
                        <path
                            d="M12 10.6667H10.6667V13.3334H8.00001V14.6667H10.6667V17.3334H12V14.6667H14.6667V13.3334H12V10.6667Z"
                            fill="white"
                        />
                        <path
                            d="M22.6667 17.3334C23.4031 17.3334 24 16.7365 24 16.0001C24 15.2637 23.4031 14.6667 22.6667 14.6667C21.9303 14.6667 21.3333 15.2637 21.3333 16.0001C21.3333 16.7365 21.9303 17.3334 22.6667 17.3334Z"
                            fill="white"
                        />
                        <path
                            d="M20 13.3334C20.7364 13.3334 21.3333 12.7365 21.3333 12.0001C21.3333 11.2637 20.7364 10.6667 20 10.6667C19.2636 10.6667 18.6667 11.2637 18.6667 12.0001C18.6667 12.7365 19.2636 13.3334 20 13.3334Z"
                            fill="white"
                        />
                    </svg>
                </div>
            </BaseTypography>
        </Button>
    ) : (
        <p></p>
    );
};
