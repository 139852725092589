import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpGiftInfoList implements ICommand
{

	public ID : number = 0;
	public Name : string = "";
	public DisplayName : string = "";
	public Order : number = 0;
	public Interval : number = 0;
	public Amount : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.ID = ba.readUint53();
		this.Name = ba.readString();
		this.DisplayName = ba.readString();
		this.Order = ba.readUint53();
		this.Interval = ba.readUint53();
		this.Amount = ba.readUint53();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint53(this.ID);
		ba.writeString(this.Name);
		ba.writeString(this.DisplayName);
		ba.writeUint53(this.Order);
		ba.writeUint53(this.Interval);
		ba.writeUint53(this.Amount);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpGiftInfoList();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'ID(Q)='+this.ID+' ';
		str += 'Name(S)='+this.Name+' ';
		str += 'DisplayName(S)='+this.DisplayName+' ';
		str += 'Order(Q)='+this.Order+' ';
		str += 'Interval(Q)='+this.Interval+' ';
		str += 'Amount(Q)='+this.Amount+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'SpGiftInfoList';
	}

}
