import { useEffect, useState } from 'react';
import { BitArray } from '../models/cmd/BitArray';
import { ByteArray } from '../models/cmd/ByteArray';

export const getFavIdxByHostID = (hostID: number) => {
    for (let i = 0; i < FavTableIndex.MAX; i++) {
        if (getHostIDByFavIdx(i) == hostID) {
            return i;
        }
    }
    return -1;
};
export const getHostIDByFavIdx = (idx: number) => {
    const table = <string>FavTableIndex[idx];
    const tmp = table.split('_');
    if (tmp.length > 1) return Number(tmp[1]);
    return 0;
};
export const updateFavoriteBit = (FPlayerPrefs3: string, hostID: number) => {
    const idx = getFavIdxByHostID(hostID);
    const value = BigInt(FPlayerPrefs3);
    const ba: ByteArray = new ByteArray();
    ba.writeUint64(value);
    ba.position = 0;
    const bitArr = new BitArray(ba);
    let binaryStr = '';
    for (let i = 0; i < 64; i++) {
        if (bitArr.readBoolean()) {
            if (idx == i) {
                binaryStr = '0' + binaryStr;
            } else {
                binaryStr = '1' + binaryStr;
            }
        } else {
            if (idx == i) {
                binaryStr = '1' + binaryStr;
            } else {
                binaryStr = '0' + binaryStr;
            }
        }
    }
    const bigInt = BigInt('0b' + binaryStr);
    return bigInt.toString();
};
export const useFavorite = (FPlayerPrefs3: string) => {
    const [favoriteTableList, setFavoriteTableList] = useState(
        new Array<number>()
    );

    useEffect(() => {
        const value = BigInt(FPlayerPrefs3);
        const ba: ByteArray = new ByteArray();
        ba.writeUint64(value);
        ba.position = 0;
        const bitArr = new BitArray(ba);
        bitArr.position = 0;
        let list = [];
        for (let i = 0; i < FavTableIndex.MAX; i++) {
            if (bitArr.readBoolean()) {
                list.push(getHostIDByFavIdx(i));
            }
        }
        setFavoriteTableList(list);
    }, [FPlayerPrefs3]);

    return favoriteTableList;
};

export enum FavTableIndex {
    BACC_839,
    BACC_841,
    SICBO_842,
    DT_843,
    BACC_844,
    BACC_845,
    BACC_871,
    POKDENG_846,
    AB_847,
    BACC_872,
    BACC_873,
    BACC_874,
    BACC_875,
    BACC_876,
    BACC_877,
    BACC_878,
    ROUL_861,
    BACC_618,
    BACC_836,
    BACC_837,
    BACC_838,
    BACC_901,
    BACC_902,
    BACC_903,
    BACC_904,
    SICBO_821,
    BACC_905,
    BACC_906,
    BACC_907,
    BACC_908,
    SEDIE_531,
    SICBO_532,
    ROUL_533,
    DT_534,
    POKDENG_535,
    BJ_536,
    AB_537,
    TP_538,
    BJ_921,
    DT_922,
    POKDENG_923,
    ROUL_924,
    SICBO_925,
    HILO_926,
    SEDIE_927,
    TP_221,
    AB_222,
    BJ_223,
    CT_224,
    HILO_539,
    FPC_540,
    BACC_854,
    BACC_855,
    BACC_856,
    BACC_857,
    BACC_858,
    BACC_831,
    BACC_832,
    BACC_833,
    BACC_834,
    BACC_835,
    ROUL_830,
    DT_850,
    DT_820,
    MAX,
}
