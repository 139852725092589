import moment from 'moment';
import { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WebSocketContext } from '../contexts/WebSocketContext';
import { ROUTE_GAME, ROUTE_LOBBY } from '../models/Route';
import { userSliceActions } from '../modules/main/slice/user';
import { popupSliceActions } from '../modules/popup/slice';
import { getProgressTimeState } from '../modules/progress/selector';
import { routeSliceActions } from '../modules/routeController/slice';
import { LocalStorageKey } from './storage/useLocalStorage';

export enum MessageCommand {
    LOGIN_PERFORMANCE,
    TIME_CHANGE,
    LOGGER_TOGGLE,
    CLOSE_SOCKET,
    GOTO_LOBBY,
    ENTER_TABLE,
    POP_MESSAGE,
    HOST_REST,
    HOST_UNKNOWN,
    VIDEO_PERFORMANCE,
    TIME_CHANGE_NEXT_LOGIN,
}

type ClientMessageCommand = {
    type: MessageCommand;
    data?: unknown;
};
const backupConsole: Console = { ...console };

export const sendToParent = (message: ClientMessageCommand) => {
    const parent = window.top;
    const origin = '*';
    if (parent) {
        console.log('backdoor::send::message', message.type, message.data);
        parent.postMessage(message, origin);
    }
};

export const useIframeMessage = () => {
    // data
    const progressTime = useSelector(getProgressTimeState);
    const loggerRef = useRef<boolean>(false);
    const dispatch = useDispatch();
    const { socketClose } = useContext(WebSocketContext);
    // method

    const loggerOn = () => {
        backupConsole.log('backdoor::logger::toggle', 'on');
        console.debug = backupConsole.debug;
        console.log = backupConsole.log;
        console.warn = backupConsole.warn;
        console.error = backupConsole.error;
        loggerRef.current = true;
    };
    const loggerOff = () => {
        backupConsole.log('backdoor::logger::toggle', 'off');
        console.debug = () => {};
        console.log = () => {};
        console.warn = () => {};
        console.error = () => {};
        loggerRef.current = false;
    };
    const toggleLogger = () => {
        if (loggerRef.current) loggerOff();
        else loggerOn();
    };

    const changeTime = (command: ClientMessageCommand) => {
        if (command.data) {
            const time = command.data as string;
            const date = new Date(`${time} GMT+0000`);
            const dateTime = moment(date).valueOf();
            if (dateTime.toString().length === 13) {
                dispatch(userSliceActions.updateNowTime(dateTime));
            } else {
                console.log('backdoor::time::change::invalid', command.data);
            }
        }
    };
    const changeTimeOnNextLogin = (command: ClientMessageCommand) => {
        if (command.data) {
            const time = command.data as string;
            const date = new Date(`${time} GMT+0000`);
            const dateTime = moment(date).valueOf();
            if (dateTime.toString().length === 13) {
                window.localStorage.setItem(
                    'next_login_time',
                    dateTime.toString()
                );
            } else {
                console.log('backdoor::time::change::invalid', command.data);
            }
        }
    };
    const MessageHandler = (event: MessageEvent<ClientMessageCommand>) => {
        let item;
        try {
            const command = event.data;
            switch (command.type) {
                case MessageCommand.TIME_CHANGE:
                    changeTime(command);
                    break;
                case MessageCommand.TIME_CHANGE_NEXT_LOGIN:
                    changeTimeOnNextLogin(command);
                    break;
                case MessageCommand.LOGGER_TOGGLE:
                    toggleLogger();
                    window.localStorage.setItem(
                        LocalStorageKey.Logger,
                        loggerRef.current ? '1' : '0'
                    );
                    break;
                case MessageCommand.CLOSE_SOCKET:
                    socketClose();
                    break;
                case MessageCommand.GOTO_LOBBY:
                    dispatch(routeSliceActions.goto(ROUTE_LOBBY));
                    break;
                case MessageCommand.ENTER_TABLE:
                    dispatch(
                        routeSliceActions.goto(`${ROUTE_GAME}/${command.data}`)
                    );
                    break;
                case MessageCommand.POP_MESSAGE:
                    item = command.data as string | string[];
                    if (item) {
                        dispatch(dispatch(popupSliceActions.open(item)));
                    }
                    break;
            }
        } catch {
            console.warn('backdoor::unknown::message', event);
        }
    };
    // side effect
    useEffect(() => {
        const isDevelopment = process.env.NODE_ENV === 'development';
        // default off the logger expect development
        loggerOff();

        const Logger =
            window.localStorage.getItem(LocalStorageKey.Logger) === '1';
        if (isDevelopment || Logger) {
            loggerOn();
        }
        // back door for qa and dev track log
        window.addEventListener('message', MessageHandler);
        return () => {
            window.removeEventListener('message', MessageHandler);
        };
    }, []);
    useEffect(() => {
        // send the message to parent when entry lobby
        console.log('hook::iframe::send', progressTime);
        const message = JSON.stringify(progressTime);
        sendToParent({
            type: MessageCommand.LOGIN_PERFORMANCE,
            data: message,
        });
    }, [progressTime]);
};
