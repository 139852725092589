import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { WebSocketContext } from '../../contexts/WebSocketContext';
import { LocalStorageKey } from '../../hooks/storage/useLocalStorage';
import {
    ROUTE_NEW_UI,
    ROUTE_ROOT,
    ROUTE_THANKS4PLAY,
} from '../../models/Route';
import { RootState } from '../../store/store';
import { replaceDefaultTable } from '../../utils/commonFunc';
import { getLandingState, getMainUserState } from '../main/selector';
import { getRouteState } from './selector';
import { routeSliceActions } from './slice';

export const RouteController = () => {
    const routeData = useSelector(getRouteState);
    const { goto, toPage } = routeData;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { returnUrl, isSupportStorage, username } = useSelector(
        (state: RootState) => getLandingState(state)
    );
    const { i18n } = useTranslation();
    const { LoginToken } = useSelector(getMainUserState);
    const { changeToNewUI } = useContext(WebSocketContext);
    useEffect(() => {
        if (goto && toPage) {
            if (toPage.indexOf(ROUTE_NEW_UI) == 0) {
                const tmpArr = toPage.split('/');
                const table = tmpArr.length > 1 ? tmpArr[1] : '';
                if (isSupportStorage) {
                    changeToNewUI(username, table);
                } else {
                    let ui = window.sessionStorage.getItem('fullParameter');
                    const new_ui_domain =
                        window.location.origin.replace('://ws.', '://ws2.') +
                        ROUTE_ROOT;

                    const pattern1: RegExp = new RegExp('lang=([^&]*)', 'i');
                    const pattern2: RegExp = new RegExp('token=([^&]*)', 'i');
                    const pattern3: RegExp = new RegExp(
                        'username=([^&]*)',
                        'i'
                    );

                    let loginParam = ui;
                    if (loginParam) {
                        loginParam = loginParam.replace(
                            pattern1,
                            'lang=' + i18n.language
                        );
                        loginParam = loginParam.replace(
                            pattern2,
                            'token=' + LoginToken
                        );
                        loginParam = loginParam.replace(
                            pattern3,
                            'username=' + username
                        );
                        if (table !== '') {
                            loginParam = replaceDefaultTable(loginParam, table);
                        }
                    }
                    if (new_ui_domain) {
                        window.location.href =
                            new_ui_domain + loginParam + '&back=true';
                    }
                }
            } else if (toPage == ROUTE_THANKS4PLAY) {
                if (returnUrl) {
                    if (returnUrl.indexOf('/') == 0) {
                        window.location.href =
                            window.location.origin + returnUrl;
                    } else if (
                        returnUrl.toLowerCase().indexOf('http://') > -1 ||
                        returnUrl.toLowerCase().indexOf('https://') > -1
                    ) {
                        window.location.href = returnUrl;
                    } else {
                        window.location.href = 'http://' + returnUrl;
                    }
                    window.localStorage.removeItem(
                        LocalStorageKey.FullParameter
                    );
                } else {
                    window.localStorage.removeItem(
                        LocalStorageKey.FullParameter
                    );
                    window.close();
                }
            }
            navigate(toPage);
            dispatch(routeSliceActions.close());
        }
        return () => {};
    }, [goto]);
    return <></>;
};
