import FavoriteIcon from '@mui/icons-material/Favorite';
import SwitchVideoIcon from '@mui/icons-material/SwitchVideo';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { getHostById } from '../../host/slice';
import { BaccaratMultiBetMap } from '../../roadmap/baccarat/map';
import '../index.scss';
// import { REPORT_COLORS } from '../../lobby/constants';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { TableMode } from '../../../models/TableMode';
import { LobbyCountdown } from '../../countdown';
import { getRoadmap } from '../../roadmap/slice';
import { MultiBetAreaPanel } from '../baccarat';

type Props = {
    hostId: number;
    isFavorite: boolean;
    switchVideo: Dispatch<SetStateAction<number>>;
    changeFavorite: Dispatch<SetStateAction<number>>;
    setTableReady: Dispatch<SetStateAction<number>>;
};
export const MultiBetTableCell = (props: Props) => {
    const { hostId, switchVideo, isFavorite, changeFavorite, setTableReady } =
        props;
    const { t } = useTranslation();
    const {
        HallStateName: hallColor,
        Rest: isRest,
        GameType,
        IsInternalTest,
    } = useSelector((state: RootState) => getHostById(state, hostId));
    const { Summary } = useSelector((state: RootState) =>
        getRoadmap(state, hostId)
    );
    const { bankerTotal, playerTotal, tieTotal } = Summary;
    const [isFav, setIsFavorite] = useState<boolean>(isFavorite);
    useEffect(() => {
        if (
            isRest === TableMode.Open ||
            (IsInternalTest && isRest === TableMode.InternalTest)
        ) {
            setTableReady(current => (current == 0 ? hostId : current));
        }
    }, []);
    return (
        <>
            <Stack direction="column" height="242px">
                <Stack
                    direction="row"
                    alignContent="center"
                    justifyContent="space-between"
                    alignItems="center"
                    className={`table-cell-info ${hallColor}`}
                >
                    <Stack
                        direction="row"
                        alignContent="center"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{
                            gap: '10px',
                            paddingLeft: '8px',
                            '& div': { marginLeft: '8px' },
                        }}
                    >
                        {IsInternalTest ? '(INT)' : ''}
                        {t(`hostList.${hostId}`)}
                        <FavoriteIcon
                            onClick={() => {
                                setIsFavorite(!isFav);
                                changeFavorite(hostId);
                                //TODO
                                // setIsFavorite(!isFavorite);
                            }}
                            sx={{
                                color: isFav ? '#B82517' : '#787878',
                                zIndex: 1,
                            }}
                        />
                        <div className="summaryList">
                            <div className="banker">{t('road.banker_win')}</div>
                            {bankerTotal}
                            <div className="player">{t('road.player_win')}</div>
                            {playerTotal}
                            <div className="tie">{t('road.draw')}</div>
                            {tieTotal}
                        </div>
                    </Stack>
                    <Stack
                        direction="row"
                        alignContent="center"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                        sx={{
                            '& div.CountdownBox': {
                                width: '127px',
                            },
                        }}
                    >
                        <>
                            <SwitchVideoIcon
                                onClick={() => {
                                    if (
                                        isRest === TableMode.Open ||
                                        (IsInternalTest &&
                                            isRest === TableMode.InternalTest)
                                    ) {
                                        switchVideo(hostId);
                                    }
                                }}
                                sx={{ color: '#BD9F71', zIndex: 1 }}
                            />
                            <LobbyCountdown
                                hostId={hostId}
                                gameType={GameType}
                            />
                        </>
                    </Stack>
                </Stack>
                <BaccaratMultiBetMap hostId={hostId} />
                <MultiBetAreaPanel hostId={hostId} />
            </Stack>
        </>
    );
};
