import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDBetLogFailSummary implements ICommand
{

	public ReportDate : string = "";
	public BetAmount : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.ReportDate = ba.readString();
		this.BetAmount = ba.readInt53();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeString(this.ReportDate);
		ba.writeUint53(this.BetAmount);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDBetLogFailSummary();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'ReportDate(S)='+this.ReportDate+' ';
		str += 'BetAmount(Q)='+this.BetAmount+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'BetLogFailSummary';
	}

}
