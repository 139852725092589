import { CMDBetSummary } from "./CMDBetSummary"
import { CMDTotalBet } from "./CMDTotalBet"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDScTotalBetFishprawncrab implements ICommand
{

	public Total : CMDTotalBet;
	public Fish : CMDBetSummary;
	public Calabash : CMDBetSummary;
	public Prawn : CMDBetSummary;
	public Crab : CMDBetSummary;
	public Tiger : CMDBetSummary;
	public Rooster : CMDBetSummary;
	public AnyTripleColor : CMDBetSummary;
	public AnyTripleSymbol : CMDBetSummary;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Total = new CMDTotalBet();
		this.Total.fromBytes(ba);
		this.Fish = new CMDBetSummary();
		this.Fish.fromBytes(ba);
		this.Calabash = new CMDBetSummary();
		this.Calabash.fromBytes(ba);
		this.Prawn = new CMDBetSummary();
		this.Prawn.fromBytes(ba);
		this.Crab = new CMDBetSummary();
		this.Crab.fromBytes(ba);
		this.Tiger = new CMDBetSummary();
		this.Tiger.fromBytes(ba);
		this.Rooster = new CMDBetSummary();
		this.Rooster.fromBytes(ba);
		this.AnyTripleColor = new CMDBetSummary();
		this.AnyTripleColor.fromBytes(ba);
		this.AnyTripleSymbol = new CMDBetSummary();
		this.AnyTripleSymbol.fromBytes(ba);
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		let tempBuf0 = this.Total.toBytes().toBuffer();
		ba.appendBuf(tempBuf0)
		let tempBuf1 = this.Fish.toBytes().toBuffer();
		ba.appendBuf(tempBuf1)
		let tempBuf2 = this.Calabash.toBytes().toBuffer();
		ba.appendBuf(tempBuf2)
		let tempBuf3 = this.Prawn.toBytes().toBuffer();
		ba.appendBuf(tempBuf3)
		let tempBuf4 = this.Crab.toBytes().toBuffer();
		ba.appendBuf(tempBuf4)
		let tempBuf5 = this.Tiger.toBytes().toBuffer();
		ba.appendBuf(tempBuf5)
		let tempBuf6 = this.Rooster.toBytes().toBuffer();
		ba.appendBuf(tempBuf6)
		let tempBuf7 = this.AnyTripleColor.toBytes().toBuffer();
		ba.appendBuf(tempBuf7)
		let tempBuf8 = this.AnyTripleSymbol.toBytes().toBuffer();
		ba.appendBuf(tempBuf8)

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDScTotalBetFishprawncrab();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Total(CMDTotalBet)='+this.Total+' ';
		str += 'Fish(CMDBetSummary)='+this.Fish+' ';
		str += 'Calabash(CMDBetSummary)='+this.Calabash+' ';
		str += 'Prawn(CMDBetSummary)='+this.Prawn+' ';
		str += 'Crab(CMDBetSummary)='+this.Crab+' ';
		str += 'Tiger(CMDBetSummary)='+this.Tiger+' ';
		str += 'Rooster(CMDBetSummary)='+this.Rooster+' ';
		str += 'AnyTripleColor(CMDBetSummary)='+this.AnyTripleColor+' ';
		str += 'AnyTripleSymbol(CMDBetSummary)='+this.AnyTripleSymbol+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 30774;
	}

	public getCmdName() : string
	{
		return 'ScTotalBetFishprawncrab';
	}

}
