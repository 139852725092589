import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GameFeature as GameFeatureEnum } from '../models/games/enums/GameFeature';
import { getMainPlayerState } from '../modules/main/selector';
import { isBitActive } from '../utils/bitwiseUtil';
import { calcLowHigh } from '../utils/commonFunc';

export const useGameFeature = () => {
    const [haveBaccSideBet, setHaveBaccSideBet] = useState<boolean>();
    const { GameFeature } = useSelector(getMainPlayerState);

    useEffect(() => {
        if (GameFeature !== undefined) {
            const curGameFeature = calcLowHigh(GameFeature);

            setHaveBaccSideBet(
                isBitActive(curGameFeature, GameFeatureEnum.BaccaratSideBet)
            );
        }
    }, [GameFeature]);

    return { haveBaccSideBet };
};
