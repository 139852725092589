import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { SupportedGame } from '../../../modules/host/models';
import { hostsSliceActions } from '../../../modules/host/slice';
import { getMainUserState } from '../../../modules/main/selector';
import store from '../../../store/store';
import { getIsRestState } from '../../../utils/commonFunc';
import { convertResultToRecord } from '../../../utils/games/teenpatti';
import { TeenPattiResult } from '../../games/teenpatti';
import { TableMode } from '../../TableMode';
import { CMDScInitTeenPatti2020, ICommand } from '../live';
import { CMDTeenPatti2020 } from '../live/CMDTeenPatti2020';
import { AbstractHandler } from './AbstractHandler';

export class CMDScInitTeenPattiHandler extends AbstractHandler {
    _command: CMDScInitTeenPatti2020;
    constructor(command: ICommand) {
        super();
        this._command = command as CMDScInitTeenPatti2020;
    }
    override convertResult(result: CMDTeenPatti2020): TeenPattiResult {
        const newResult = Object.assign(
            this.convertBaseResult(result.BaseResult),
            {
                PlayerACard: result.PlayerACard,
                PlayerBCard: result.PlayerBCard,
            }
        ) as TeenPattiResult;
        return newResult;
    }
    override handle(dispatch: Dispatch<AnyAction>): void {
        const state = store.getState();
        const { IsInternalTest } = getMainUserState(state);
        const game = {} as SupportedGame;
        const info = this.convertInformation(this._command.Information);
        const hostId = info.HostId;
        game.CurrentState = this._command.CurrentState;
        game.CurrentResult = this.convertResult(this._command.CurrentResult);
        game.OldResult = this._command.OldResult.filter(o => {
            if (o.BaseResult.Result === 0) return false;
            return true;
        }).map(o => this.convertResult(o));
        game.OldResult = game.OldResult.filter(
            (arr, index, self) =>
                index === self.findIndex(t => t.GameID === arr.GameID)
        );
        game.ResultReleased = false;
        game.IsRest = getIsRestState(
            this._command.Information.Rest,
            IsInternalTest
        );
        game.IsInternalTest =
            IsInternalTest &&
            this._command.Information.Rest == TableMode.InternalTest;

        if (game.OldResult.length > 100) {
            game.OldResult = game.OldResult.slice(48);
        }

        if (
            game.OldResult &&
            game.OldResult.length > 0 &&
            game.OldResult[0].GameCount != 1
        ) {
            let offset = game.OldResult[0].GameCount % 6;
            game.OldResult = game.OldResult.slice(6 - offset + 1);
        }

        const convertedRecord = game.OldResult.map(r =>
            convertResultToRecord(r as TeenPattiResult)
        );

        batch(() => {
            dispatch(hostsSliceActions.gameInit({ hostId, game }));
            dispatch(hostsSliceActions.updateInformation(info));
            dispatch(
                hostsSliceActions.updateRecord({
                    hostId,
                    record: convertedRecord,
                })
            );
        });
    }
}
