import { Grid, IconButton } from '@mui/material';
import Stack from '@mui/material/Stack/Stack';
import { ReactNode, useContext, useEffect, useId, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { WebSocketContext } from '../../../../contexts/WebSocketContext';
import { useCurrentTime } from '../../../../hooks/useCurrentTime';
import { useGameFeature } from '../../../../hooks/useGameFeature';
import { GameType as GameTypeEnum } from '../../../../models/games/enums/GameType';
import { ROUTE_NEW_UI } from '../../../../models/Route';
import { getMainUserState } from '../../../main/selector';
import { routeSliceActions } from '../../../routeController/slice';
import { getLobbyStateHallGameTypeMap } from '../../selector';
import Checkbox from './Checkbox';
import './NewUIPopup.scss';
import { PopupCloseIcon } from './PopupCloseIcon';

const READ_STRING = 'read';
type Notice = {
    key: string;
    startTime: number;
    endTime: number;
    image: string;
    langs: Array<string>;
    snooze41week: boolean;
};
const notices = [
    {
        key: 'fpc',
        startTime: 1744029000000,
        endTime: 1744633800000,
        image: 'FPC_720x1152_en.jpg',
        langs: ['th-TH', 'zh-CN', 'zh-TW', 'ko-KR', 'vi-VN'],
        snooze41week: false,
    },
    {
        key: 'new_ui',
        startTime: 1744633800000,
        endTime: 1776169800000,
        image: '20250327_SA_NewUI_720x1152_en.jpg',
        langs: ['th-TH', 'zh-CN', 'zh-TW', 'ko-KR', 'vi-VN', 'id-ID'],
        snooze41week: true,
    },
] as Array<Notice>;
export const NewUIPopup = () => {
    const uniqueId1 = useId();
    const { i18n } = useTranslation();
    const [show, setShow] = useState(false);
    const [currentNotice, setCurrentNotice] = useState<Notice>();
    const [isChecked, setIsChecked] = useState(false);
    const [image, setImage] = useState<ReactNode>(<></>);
    const [showCheckBox, setShowCheckBox] = useState<boolean>(false);
    const { currentTimeStamp } = useCurrentTime();
    const { Username } = useSelector(getMainUserState);
    const dispatch = useDispatch();
    const { haveBaccSideBet } = useGameFeature();
    const hostList = useSelector(getLobbyStateHallGameTypeMap);
    const {
        hasLogin: { current },
    } = useContext(WebSocketContext);
    const havePermission = (key: string) => {
        if (key === 'fpc') {
            if (
                hostList.find(
                    host => host.gameType === GameTypeEnum.FishPrawnCrab
                )
            ) {
                return true;
            }
        }
        return true;
    };
    useEffect(() => {
        if (current && hostList && haveBaccSideBet !== undefined) {
            for (let i = 0; i < notices.length; i++) {
                let n = notices[i];
                let isShowed = false;
                if (n['snooze41week'] == true) {
                    const lastSession = localStorage.getItem(
                        `notice_s41w_session_${n.key}_${Username}`
                    );
                    if (uniqueId1 == lastSession) continue;
                    const next = localStorage.getItem(
                        `notice_s41w_${n.key}_${Username}`
                    );
                    if (next && new Date(next).getTime() > currentTimeStamp())
                        continue;
                } else {
                    isShowed =
                        localStorage.getItem(`${n.key}_${Username}`) == 'read';
                }
                if (isShowed) continue;
                if (
                    currentTimeStamp() >= n.startTime &&
                    currentTimeStamp() < n.endTime
                ) {
                    if (havePermission(n.key)) {
                        setCurrentNotice(n);
                    }
                    break;
                }
            }
        }
    }, [current, hostList, haveBaccSideBet]);
    useEffect(() => {
        if (currentNotice) {
            let path = `${process.env.PUBLIC_URL}/resource/assets/image/new_ui/${currentNotice.image}`;
            if (currentNotice.langs.indexOf(i18n.language) >= 0) {
                switch (i18n.language) {
                    case 'th-TH':
                        path = path.replace('en.', 'th.');
                        break;
                    case 'ko-KR':
                        path = path.replace('en.', 'kr.');
                        break;
                    case 'vi-VN':
                        path = path.replace('en.', 'vn.');
                        break;
                    case 'zh-CN':
                        path = path.replace('en.', 'sc.');
                        break;
                    case 'zh-TW':
                        path = path.replace('en.', 'tc.');
                        break;
                    case 'ms-MY':
                        path = path.replace('en.', 'my.');
                        break;
                    case 'id-ID':
                        path = path.replace('en.', 'in.');
                        break;
                    case 'ja-JP':
                        path = path.replace('en.', 'jp.');
                        break;
                    case 'es-ES':
                        path = path.replace('en.', 'sp.');
                        break;
                    case 'pt-PT':
                        path = path.replace('en.', 'pt.');
                        break;
                    case 'pt-BR':
                        path = path.replace('en.', 'br.');
                        break;
                    case 'hi-IN':
                        path = path.replace('en.', 'hi.');
                        break;
                    case 'ar-001':
                        path = path.replace('en.', 'ar.');
                        break;
                    case 'fa-IR':
                        path = path.replace('en.', 'fa.');
                        break;
                    case 'bn-BN':
                        path = path.replace('en.', 'bn.');
                        break;
                    case 'te-IN':
                        path = path.replace('en.', 'te.');
                        break;
                    default:
                        break;
                }
            }
            setImage(<img src={path} />);
            setShow(true);
            setShowCheckBox(currentNotice.snooze41week);
        } else {
            setShow(false);
        }
    }, [currentNotice, i18n.language]);

    const formatDate = (date: Date) => {
        let d = date,
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    };
    const setRead = () => {
        if (currentNotice) {
            if (currentNotice['snooze41week'] == true && isChecked) {
                let result = new Date(currentTimeStamp());
                result.setDate(result.getDate() + 7);
                localStorage.setItem(
                    `notice_s41w_${currentNotice.key}_${Username}`,
                    formatDate(result)
                );
            } else {
                localStorage.setItem(
                    `${currentNotice.key}_${Username}`,
                    READ_STRING
                );
            }
            setCurrentNotice(undefined);
        }
    };
    const handleClose = () => {
        setRead();
        setShow(false);
    };
    const gotoNewUI = () => {
        setRead();
        dispatch(routeSliceActions.goto(ROUTE_NEW_UI));
    };
    return (
        <>
            {show && (
                <div className="new-ui-popup">
                    <Grid className="new-ui-container">
                        <Stack>
                            <Grid className="new-ui-header">
                                <IconButton
                                    sx={{
                                        height: '2.333rem',
                                        width: '2.333rem',
                                    }}
                                    onClick={handleClose}
                                >
                                    <PopupCloseIcon />
                                </IconButton>
                            </Grid>
                            <div className="new-ui-try-button">
                                <IconButton
                                    sx={{ height: '100%', width: '100%' }}
                                    onClick={gotoNewUI}
                                >
                                    {image}
                                </IconButton>
                            </div>
                            {showCheckBox && (
                                <Checkbox
                                    css="checkbox-wrapper"
                                    label="new_ui.snooze_week"
                                    isChecked={isChecked}
                                    setIsChecked={setIsChecked}
                                />
                            )}
                        </Stack>
                    </Grid>
                </div>
            )}
        </>
    );
};
