import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CommonConfigContext } from '../../../../contexts/ConfigContext';
import { VoiceCode, Voice as VoiceEnum } from '../../../../models/Voice';
import { getMainUserState } from '../../../main/selector';

type VoiceSelectorProps = {
    voiceSound: VoiceEnum;
    setVoiceSound: Dispatch<SetStateAction<VoiceEnum>>;
    playButtonAudio: () => void;
};
export const VoiceSelector = (props: VoiceSelectorProps) => {
    const { voiceSound, setVoiceSound, playButtonAudio } = props;
    const id = 'voice-menu';
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { langOnly, langExclude } = useContext(CommonConfigContext);
    const { LobbyCode: serLobbyCode } = useSelector(getMainUserState);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        playButtonAudio();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleChangeVoice = (voice: VoiceEnum) => {
        setVoiceSound(voice);
        handleClose();
        playButtonAudio();
    };

    const voiceOrder: Array<number> = [
        VoiceEnum.English1,
        VoiceEnum.Thai1,
        VoiceEnum.Korean1,
        VoiceEnum.Vietnamese,
        VoiceEnum.Mandarin1,
        //VoiceEnum.Mandarin2,
        VoiceEnum.Cantonese1,
        //VoiceEnum.Cantonese2,
        VoiceEnum.Indonesian1,
        VoiceEnum.Japanese,
        VoiceEnum.BrazilianPortuguese,
        VoiceEnum.Arabic,
        VoiceEnum.Hindi,
    ];

    return (
        <Box className="voice-selector" sx={{ width: '35.2vw' }}>
            <Button
                aria-controls={open ? id : undefined}
                aria-haspopup={true}
                aria-expanded={open ? true : undefined}
                variant="contained"
                disableElevation
                onClick={handleOpen}
                endIcon={!open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                fullWidth={true}
                sx={{
                    height: '7vw',
                    textTransform: 'none',
                    fontSize: '3vw',
                    lineHeight: '1',
                }}
            >
                {/* {VoiceEnum[voiceSound]} */}
                {t(`setting.sound_list.${voiceSound}`)}
            </Button>
            <Menu
                id={id}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {Object.values(voiceOrder)
                    .filter(value => typeof value === 'number')
                    .filter(l => {
                        const voice = l as VoiceEnum;
                        const shortLang = VoiceCode[voice];
                        if (
                            langExclude[shortLang]?.lobby?.includes(
                                serLobbyCode
                            ) ||
                            langExclude[shortLang]?.lobby?.includes('all')
                        )
                            return false;
                        if (langOnly[shortLang]) {
                            if (langOnly[shortLang]?.includes(serLobbyCode)) {
                                return true;
                            }
                            return false;
                        }
                        return true;
                    })
                    .map(v => Number(v))
                    .map(v => (
                        <MenuItem
                            onClick={() => handleChangeVoice(v)}
                            key={`voice-selector-${v}`}
                        >
                            {t(`setting.sound_list.${v}`)}
                        </MenuItem>
                    ))}
            </Menu>
        </Box>
    );
};
