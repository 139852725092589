import { CMDSpGiftInfoList } from "./CMDSpGiftInfoList"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpInitGift implements ICommand
{

	public AccountType : number = 0;
	public CurrencyGiftEnable : number = 0;
	public LobbyGiftEnable : number = 0;
	public giftList : Array<CMDSpGiftInfoList>;

	public constructor()
	{
		this.giftList = new Array<CMDSpGiftInfoList>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.AccountType = ba.readUint16();
		this.CurrencyGiftEnable = ba.readUint16();
		this.LobbyGiftEnable = ba.readUint16();
		let giftListSize:number = ba.readUint53();
		while (giftListSize-- > 0)
		{
			let giftListChild:CMDSpGiftInfoList = new CMDSpGiftInfoList();
			giftListChild.fromBytes(ba);
			this.giftList.push(giftListChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint16(this.AccountType);
		ba.writeUint16(this.CurrencyGiftEnable);
		ba.writeUint16(this.LobbyGiftEnable);
		ba.writeUint53(this.giftList.length);
		for (const giftListChild of this.giftList)
		{
			let tempBuf = giftListChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpInitGift();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'AccountType(W)='+this.AccountType+' ';
		str += 'CurrencyGiftEnable(W)='+this.CurrencyGiftEnable+' ';
		str += 'LobbyGiftEnable(W)='+this.LobbyGiftEnable+' ';
		str += 'giftList_size(Q)='+this.giftList.length + ' ';
		str += 'giftList(CMDSpGiftInfoList)=[';
		for (let giftListChild in this.giftList)
		{
			if (<number><unknown>giftListChild > 0) str += ', ';
			str += this.giftList[giftListChild].toString();
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 50136;
	}

	public getCmdName() : string
	{
		return 'SpInitGift';
	}

}
