import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDGiftLog implements ICommand
{

	public HostID : number = 0;
	public UTCSecond : number = 0;
	public GameCount : number = 0;
	public DealerName : string = "";
	public ID : number = 0;
	public Balance : number = 0;
	public GiftAmount : number = 0;
	public GiftType : number = 0;
	public GameType : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.HostID = ba.readUint16();
		this.UTCSecond = ba.readInt53();
		this.GameCount = ba.readUint32();
		this.DealerName = ba.readString();
		this.ID = ba.readInt53();
		this.Balance = ba.readInt53();
		this.GiftAmount = ba.readInt53();
		this.GiftType = ba.readUint16();
		this.GameType = ba.readInt53();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint16(this.HostID);
		ba.writeUint53(this.UTCSecond);
		ba.writeUint32(this.GameCount);
		ba.writeString(this.DealerName);
		ba.writeUint53(this.ID);
		ba.writeUint53(this.Balance);
		ba.writeUint53(this.GiftAmount);
		ba.writeUint16(this.GiftType);
		ba.writeUint53(this.GameType);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDGiftLog();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'HostID(W)='+this.HostID+' ';
		str += 'UTCSecond(Q)='+this.UTCSecond+' ';
		str += 'GameCount(D)='+this.GameCount+' ';
		str += 'DealerName(S)='+this.DealerName+' ';
		str += 'ID(Q)='+this.ID+' ';
		str += 'Balance(Q)='+this.Balance+' ';
		str += 'GiftAmount(Q)='+this.GiftAmount+' ';
		str += 'GiftType(W)='+this.GiftType+' ';
		str += 'GameType(Q)='+this.GameType+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'GiftLog';
	}

}
