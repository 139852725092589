import { RoadMapProps } from '.';
import { GameType as CommonGameType } from '../../../../models/games/enums/GameType';
import { AndarBaharSwitchTableMap } from '../../../roadmap/andarBahar';
import { SwitchTableMap } from '../../../roadmap/baccarat/map';
import { BlackjackSwitchTableMap } from '../../../roadmap/blackjack';
import { DragonTigerSwitchTableMap } from '../../../roadmap/dragonTiger';
import { PokDengSwitchTableMap } from '../../../roadmap/pokdeng';
import { RouletteSwitchTableMap } from '../../../roadmap/roulette';
import { SedieSwitchTableMap } from '../../../roadmap/sedie';
import { SicBoSwitchTableMap } from '../../../roadmap/sicbo';
import { TeenPattiSwitchTableMap } from '../../../roadmap/teenpatti';
import { NewGameLabel } from './NewGameRoadMap';
export const SwitchTableRoadMap = (props: RoadMapProps) => {
    const { hostId, gameType } = props;
    switch (gameType) {
        case CommonGameType.Baccarat:
            return <SwitchTableMap hostId={hostId} />;
        case CommonGameType.Dragon:
            return <DragonTigerSwitchTableMap hostId={hostId} />;
        case CommonGameType.Blackjack:
            return <BlackjackSwitchTableMap hostId={hostId} />;
        case CommonGameType.PokDeng:
            return <PokDengSwitchTableMap hostId={hostId} />;
        case CommonGameType.SeDie:
            return <SedieSwitchTableMap hostId={hostId} />;
        case CommonGameType.SicBo:
            return <SicBoSwitchTableMap hostId={hostId} />;
        case CommonGameType.Roulette:
            return <RouletteSwitchTableMap hostId={hostId} />;
        case CommonGameType.AndarBahar:
            return <AndarBaharSwitchTableMap hostId={hostId} />;
        case CommonGameType.TeenPatti2020:
            return <TeenPattiSwitchTableMap hostId={hostId} />;
        default:
            return <NewGameSwitchTableRoadMap {...props} />;
        // return <>Host no exist</>;
    }
};
const NewGameSwitchTableRoadMap = ({ gameType }: RoadMapProps) => {
    return (
        <div className="newgame-switch-table-map-main-container">
            <img
                width="auto"
                height="100%"
                src={`${process.env.PUBLIC_URL}/resource/assets/image/new_ui/game_small_${gameType}.png`}
            />

            <NewGameLabel />
        </div>
    );
};
