import { Voice as VoiceEnum } from '../models/Voice';

export type LocalePair = {
    local: string;
    code: string;
};
export type LocaleCode = {
    long: string;
    short: string;
    map_code: string;
    country: string;
    fullName: string;
    voice: VoiceEnum;
    // node_modules\moment\locale\
    moment: string;
};
export type LocaleCodeType = 'long' | 'short' | 'moment';
export const Locale: Record<string, LocaleCode> = {
    en_US: {
        long: 'en-US',
        short: 'en',
        map_code: 'en',
        country: 'US',
        fullName: 'English',
        voice: VoiceEnum.English1,
        moment: 'en',
    },
    th_TH: {
        long: 'th-TH',
        short: 'th',
        map_code: 'th',
        country: 'TH',
        fullName: 'ไทย',
        voice: VoiceEnum.Thai1,
        moment: 'th',
    },
    ko_KR: {
        long: 'ko-KR',
        short: 'kr',
        map_code: 'kr',
        country: 'KR',
        fullName: '한국어',
        voice: VoiceEnum.Korean1,
        moment: 'ko',
    },
    vi_VN: {
        long: 'vi-VN',
        short: 'vn',
        map_code: 'vn',
        country: 'VN',
        fullName: 'Tiếng Việt',
        voice: VoiceEnum.Vietnamese,
        moment: 'vi',
    },
    zh_CN: {
        long: 'zh-CN',
        short: 'sc',
        map_code: 'cn',
        country: 'CN',
        fullName: '简体中文',
        voice: VoiceEnum.Mandarin1,
        moment: 'zh-cn',
    },
    zh_TW: {
        long: 'zh-TW',
        short: 'tc',
        map_code: 'zh',
        country: 'TW',
        fullName: '繁體中文',
        voice: VoiceEnum.Mandarin1,
        moment: 'zh-tw',
    },
    ms_MY: {
        long: 'ms-MY',
        short: 'ms',
        map_code: 'ms',
        country: 'MY',
        fullName: 'Bahasa Melayu',
        voice: VoiceEnum.English1,
        moment: 'ms',
    },
    id_ID: {
        long: 'id-ID',
        short: 'id',
        map_code: 'id',
        country: 'ID',
        fullName: 'Bahasa Indonesia',
        voice: VoiceEnum.Indonesian1,
        moment: 'id',
    },
    ja_JP: {
        long: 'ja-JP',
        short: 'jp',
        map_code: 'jp',
        country: 'JP',
        fullName: '日本語',
        voice: VoiceEnum.Japanese,
        moment: 'ja',
    },
    es_ES: {
        long: 'es-ES',
        short: 'es',
        map_code: 'es',
        country: 'ES',
        fullName: 'Español',
        voice: VoiceEnum.English1,
        moment: 'es',
    },
    pt_PT: {
        long: 'pt-PT',
        short: 'pt',
        map_code: 'pt',
        country: 'PT',
        fullName: 'Português',
        voice: VoiceEnum.English1,
        moment: 'pt',
    },
    pt_BR: {
        long: 'pt-BR',
        short: 'pt_br',
        map_code: 'pt_br',
        country: 'BR',
        fullName: 'Português Brasileiro',
        voice: VoiceEnum.BrazilianPortuguese,
        moment: 'pt-br',
    },
    ar_001: {
        long: 'ar-001',
        short: 'ar',
        map_code: 'ar',
        country: 'EG',
        fullName: 'فشا عربي ',
        voice: VoiceEnum.Arabic,
        moment: 'ar',
    },
    fa_IR: {
        long: 'fa-IR',
        short: 'fa',
        map_code: 'fa',
        country: 'IR',
        fullName: 'فارسی',
        voice: VoiceEnum.English1,
        moment: 'fa',
    },
    hi_IN: {
        long: 'hi-IN',
        short: 'hi',
        map_code: 'hi',
        country: 'IN',
        fullName: 'हिंदी',
        voice: VoiceEnum.Hindi,
        moment: 'hi',
    },
    bn_BN: {
        long: 'bn-BN',
        short: 'bn',
        map_code: 'bn',
        country: 'BN',
        fullName: 'বাঙ্গালী',
        voice: VoiceEnum.Hindi,
        moment: 'bn',
    },
    te_IN: {
        long: 'te-IN',
        short: 'te',
        map_code: 'te',
        country: 'IN',
        fullName: 'తెలుగు',
        voice: VoiceEnum.Hindi,
        moment: 'te',
    },
};
export const getLocaleCode = (key: LocaleCodeType) =>
    Object.keys(Locale).map(
        l => ({ local: l, code: Locale[l][key] }) as LocalePair
    );
export const getLocaleByCode = (key: LocaleCodeType, code: string) => {
    const locale = Object.values(Locale).find(l => l[key] === code);
    return locale ?? Locale.en_US;
};
