import { Box, Stack, Typography } from '@mui/material';
import BaseImage from '../../../../components/common/baseImage';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { promotionToolSliceActions } from '../../../main/slice/promotiontool/promotionTool';
import { PromotionPage } from './PromotionDialog';
import { PromotionRankingCell } from './PromotionRankingCell';
import { useContext, useEffect, useState } from 'react';
import { CMDPsQueryPromotionRanking } from '../../../../models/cmd/live';
import { WebSocketContext } from '../../../../contexts/WebSocketContext';
import { RootState } from '../../../../store/store';
import {
    getMainUserState,
    getPromotionToolState,
} from '../../../main/selector';
import { useTranslation } from 'react-i18next';
import {
    dateToString,
    getDisplayTimeZone,
    startQuery,
    stopQuery,
    timeToString,
} from '../PromotionUtil';
import { popupSliceActions } from '../../slice';
import { POPUP_CONFIRM_ACTION, POPUP_TYPE } from '../../../../models/Popup';
import { getPopupState } from '../../selector';
import {
    displayCurrencyName,
    mergeCurrencyName,
    numberFormat,
} from '../../../../utils/commonFunc';
import { PromotionButton } from './PromotionButton';
import { CommonConfigContext } from '../../../../contexts/ConfigContext';

export type RankingProps = {
    Rank: number;
    PlayerID: number;
    Username: string;
    WinAmount: number;
    LastRank: boolean;
    Self: boolean;
};
export const PromotionRanking = () => {
    const dispatch: Dispatch<AnyAction> = useDispatch();
    const { sendCommand } = useContext(WebSocketContext);
    const {
        RankData,
        PlayerRank,
        PlayerAmount,
        RankingUTCUpdateTime,
        RankCount,
        Timezone,
        EndTimestamp,
    } = useSelector((state: RootState) => getPromotionToolState(state));
    const confirmFunc = useSelector(getPopupState).confirmValue;

    //Currency handling
    const { showCurrencyName } = useContext(CommonConfigContext);
    const { IsTrial, CurrencyName, PlayerID, LobbyCode } = useSelector(
        (state: RootState) => getMainUserState(state)
    );
    const currency = mergeCurrencyName(
        useSelector((state: RootState) => getMainUserState(state).CurrencyName)
    );
    const isShowCurrency = displayCurrencyName(
        showCurrencyName,
        CurrencyName,
        LobbyCode
    );

    const [ranking, setRanking] = useState<RankingProps[]>([]);
    const { t } = useTranslation();

    const [showPlayerRank, setShowPlayerRank] = useState(false);
    const [isPlayerFirst3, setPlayerFirst3] = useState(false);
    const [displayPlayerRank, setDisplayPlayerRank] = useState('-');

    const [showPlayerName, setShowPlayerName] = useState(false);
    const [displayPlayerName, setDisplayPlayerName] = useState(t('system.you'));

    const [showPlayerAmount, setShowPlayerAmount] = useState(false);
    const [displayPlayerAmount, setDisplayPlayerAmount] = useState('-');

    const displayTimezone = getDisplayTimeZone(Timezone);

    const doQuery = () => {
        dispatch(promotionToolSliceActions.isLoading(true));
        const cmd = new CMDPsQueryPromotionRanking();
        sendCommand(cmd);
        startQuery(onTimeout);

        if (eventEnded()) {
            //get last data when event ended
            updateRankData();
        }
    };

    const onTimeout = () => {
        stopQuery();
        dispatch(promotionToolSliceActions.isLoading(false));
        dispatch(
            popupSliceActions.open(
                'promotion_tool.query_timeout',
                POPUP_CONFIRM_ACTION.RETRY,
                POPUP_TYPE.CONFIRM,
                undefined
            )
        );
    };

    useEffect(() => {
        doQuery();
    }, []);

    useEffect(() => {
        updateRankData();
    }, [RankData, PlayerAmount, PlayerRank, RankingUTCUpdateTime, RankCount]);

    useEffect(() => {
        if (confirmFunc === POPUP_CONFIRM_ACTION.RETRY) {
            doQuery();
        }
    }, [confirmFunc]);

    const updateRankData = () => {
        const isEmptyData = !RankData || RankData.length == 0;
        if (RankData) {
            dispatch(promotionToolSliceActions.isLoading(false));
            stopQuery();
            setShowPlayerName(true);
            setShowPlayerRank(true);
            setShowPlayerAmount(true);
        }

        //Ranking
        let ranking: RankingProps[] = [];
        if (!isEmptyData) {
            //update
            RankData.forEach((str, index) => {
                const data: RankingProps = JSON.parse(str);
                data.LastRank = index === RankData.length - 1;
                data.Self = data.PlayerID === PlayerID;
                ranking.push(data);
            });
        }
        setRanking(ranking);

        //Player
        setPlayerFirst3(PlayerRank > 0 && PlayerRank <= 3);

        if (!isEmptyData) {
            setDisplayPlayerRank(
                getRankString(PlayerRank, PlayerAmount, RankCount, ranking)
            );
            setDisplayPlayerAmount(
                PlayerAmount > 0 ? numberFormat(PlayerAmount / 100) : '-'
            );
        }

        // Trial player account handling
        if (IsTrial && !isEmptyData) {
            setDisplayPlayerRank('-');
            setDisplayPlayerName('-');
            setDisplayPlayerAmount('-');
        }
    };

    const getRankString = (
        rank: number,
        winAmount: number,
        rankCount: number,
        rankData: Array<RankingProps>
    ): string => {
        if (rank > 0) {
            if (winAmount > 0) {
                if (rank <= rankData.length) return rank.toString();
                else if (rank < rankCount) return `${rankData.length}+`;
            }
        } else if (rank <= 0 && winAmount > 0) {
            return `${rankCount}+`;
        }
        return '-';
    };

    const eventEnded = (): boolean => {
        const timezone = -displayTimezone;
        const timeDifference =
            EndTimestamp * 1000 + timezone * 60 * 60 * 1000 - Date.now();
        return timeDifference < 0;
    };

    const destroy = () => {
        stopQuery();
        dispatch(promotionToolSliceActions.clearRanking());
    };

    return (
        <Box>
            <Box
                sx={{
                    position: 'relative',
                    top: '-43px',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <BaseImage
                    className={'event_title event_title'}
                    isMultiLang={true}
                    scale={1}
                />
            </Box>

            <Box sx={{ position: 'relative', top: '-27px' }}>
                <Box
                    sx={{
                        backgroundColor: '#bea57b',
                        width: '100%',
                        height: '2px',
                    }}
                ></Box>
                <Box
                    sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        width: '100%',
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            color: '#bf9a5d',
                            textAlign: 'center',
                            fontSize: '18px',
                        }}
                    >
                        {t('promotion_tool.rankings')}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        backgroundColor: '#bea57b',
                        width: '100%',
                        height: '2px',
                    }}
                ></Box>
                <Typography
                    sx={{
                        position: 'absolute',
                        minWidth: '48px',
                        margin: '16px 432px 0 20px',
                        color: '#979797',
                        textAlign: 'center',
                        fontSize: '14px',
                    }}
                >
                    {t('promotion_tool.rankings_num')}
                </Typography>
                <Typography
                    sx={{
                        position: 'absolute',
                        minWidth: '116px',
                        margin: '16px 252px 0 132px',
                        color: '#979797',
                        textAlign: 'center',
                        fontSize: '14px',
                    }}
                >
                    {t('promotion_tool.rankings_player')}
                </Typography>
                <Typography
                    sx={{
                        position: 'absolute',
                        minWidth: '172px',
                        margin: '16px 20px 0 308px',
                        color: '#979797',
                        textAlign: 'right',
                        fontSize: '14px',
                    }}
                >
                    {`${t('promotion_tool.rankings_win_amount')} ${isShowCurrency ? '(' + currency + ')' : ''}`}
                </Typography>
                <Box
                    sx={{
                        width: '100%',
                        minHeight: '470px',
                        maxHeight: '470px',
                        overflowY: 'auto',
                        marginTop: '41px',
                    }}
                >
                    {ranking.map((rank, index) => (
                        <PromotionRankingCell
                            ranking={rank}
                            key={`promotiontool-ranking-${index}`}
                        />
                    ))}
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        height: '60px',
                        background: 'rgba(35, 31, 32, 0.50)',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            maxWidth: '48px',
                            maxHeight: '48px',
                            display: 'flex',
                            justifyContent: 'center',
                            margin: '0 432px 0 20px',
                        }}
                    >
                        {showPlayerRank ? (
                            isPlayerFirst3 ? (
                                <BaseImage
                                    className={`promotion_tool event_no_${PlayerRank}`}
                                    scale={1}
                                />
                            ) : (
                                <Typography
                                    sx={{
                                        minWidth: '48px',
                                        minHeight: '48px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontSize: '20px',
                                    }}
                                >
                                    {displayPlayerRank}
                                </Typography>
                            )
                        ) : null}
                    </Box>
                    {showPlayerName ? (
                        <Typography
                            sx={{
                                position: 'absolute',
                                minWidth: '116px',
                                margin: '0 252px 0 132px',
                                textAlign: 'center',
                                fontSize: '20px',
                            }}
                        >
                            {displayPlayerName}
                        </Typography>
                    ) : (
                        <></>
                    )}
                    {showPlayerAmount ? (
                        <Typography
                            sx={{
                                position: 'absolute',
                                minWidth: '172px',
                                margin: '0 20px 0 308px',
                                textAlign: 'right',
                                fontSize: '20px',
                            }}
                        >
                            {displayPlayerAmount}
                        </Typography>
                    ) : (
                        <></>
                    )}
                </Box>
                <Stack
                    direction={'row'}
                    spacing={0.5}
                    sx={{
                        margin: '15px 20px 15px 0',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Typography
                        sx={{
                            color: '#979797',
                            textAlign: 'right',
                            fontSize: '14px',
                        }}
                    >{`${t('promotion_tool.last_update')}`}</Typography>
                    <Typography
                        sx={{
                            color: '#979797',
                            textAlign: 'right',
                            fontSize: '14px',
                        }}
                    >
                        {RankingUTCUpdateTime > 0
                            ? `${dateToString(new Date((RankingUTCUpdateTime + displayTimezone * 60 * 60) * 1000), false, '-')} ${timeToString(new Date((RankingUTCUpdateTime + displayTimezone * 60 * 60) * 1000), false, ':')} (UTC${displayTimezone >= 0 ? '+' + displayTimezone : displayTimezone})`
                            : `-`}
                    </Typography>
                </Stack>
            </Box>
            <Stack
                direction={'row'}
                sx={{
                    position: 'absolute',
                    bottom: '2%',
                    left: '50%',
                    transform: 'translateX(-50%)',
                }}
                spacing={'20px'}
            >
                <PromotionButton
                    className="button_gold"
                    onClick={() => {
                        destroy();
                        dispatch(
                            promotionToolSliceActions.updatePage(
                                PromotionPage.Gacha
                            )
                        );
                    }}
                >
                    {t('promotion_tool.explore_rewards')}
                </PromotionButton>
                <PromotionButton
                    className="button_black"
                    onClick={() => {
                        destroy();
                        dispatch(
                            promotionToolSliceActions.updatePage(
                                PromotionPage.Record
                            )
                        );
                    }}
                >
                    {t('promotion_tool.records')}
                </PromotionButton>
            </Stack>
            {RankData && ranking.length < 1 ? (
                <Typography
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        top: '160px',
                        color: '#FFFFFF',
                        textAlign: 'center',
                        fontSize: '28px',
                    }}
                >
                    {t('promotion_tool.no_records')}
                </Typography>
            ) : (
                <></>
            )}
        </Box>
    );
};
