import { Box, Typography } from '@mui/material';
import BaseImage from '../../../../components/common/baseImage';
import { RankingProps } from './PromotionRanking';
import { numberFormat } from '../../../../utils/commonFunc';
import { useTranslation } from 'react-i18next';

type PromotionRankingCellProps = {
    ranking: RankingProps;
};
export const PromotionRankingCell = (props: PromotionRankingCellProps) => {
    const { t } = useTranslation();
    const ranking = props.ranking;
    const first3 = ranking.Rank > 0 && ranking.Rank <= 3;
    const isSelf = ranking.Self;

    const getFontColor = () => {
        return isSelf || ranking.Rank == 1 ? '#ffffff' : '#979797';
    };

    const getBackgroundColor = () => {
        return isSelf ? 'rgba(10, 89, 88, 1)' : 'rgba(0, 0, 0, 0)';
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                width: '100%',
                height: '52px',
                overflow: 'hidden',
                backgroundColor: getBackgroundColor(),
            }}
        >
            <Box
                sx={{
                    maxWidth: '48px',
                    maxHeight: '48px',
                    display: 'flex',
                    justifyContent: 'center',
                    marginLeft: '20px',
                }}
            >
                {first3 ? (
                    <BaseImage
                        className={`promotion_tool event_no_${ranking.Rank}`}
                        scale={1}
                    />
                ) : (
                    <Typography
                        sx={{
                            minWidth: '48px',
                            minHeight: '48px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '20px',
                            color: getFontColor(),
                        }}
                    >
                        {ranking.Rank.toString()}
                    </Typography>
                )}
            </Box>
            <Typography
                sx={{
                    marginLeft: '20px',
                    marginRight: '20px',
                    width: '204px',
                    textAlign: 'center',
                    fontSize: '20px',
                    color: getFontColor(),
                }}
            >
                {isSelf ? t('system.you') : ranking.Username}
            </Typography>
            <Typography
                sx={{
                    marginRight: '20px',
                    textAlign: 'right',
                    width: '168px',
                    fontSize: '20px',
                    color: getFontColor(),
                }}
            >
                {numberFormat(ranking.WinAmount / 100)}
            </Typography>
            {!ranking.LastRank ? (
                <Box
                    sx={{
                        backgroundColor: '#ab8b56',
                        width: '476px',
                        height: '1px',
                        opacity: '0.2',
                        marginLeft: '12px',
                        marginRight: '12px',
                    }}
                />
            ) : (
                <></>
            )}
        </Box>
    );
};
