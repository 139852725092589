import md5 from 'md5';
import moment from 'moment';
import { LocalStorageKey } from '../hooks/storage/useLocalStorage';
import { CommonConfigType } from '../hooks/useConfig';
import { ROUTE_ROOT } from '../models/Route';
import { TableMode } from '../models/TableMode';
import { PokerCard, Suit } from '../models/games/PokerCard';
import { ShoeRound } from '../models/games/ShoeRound';
import { GameType as GameTypeEnum } from '../models/games/enums/GameType';
import { Bet, BetPlayerAmount } from '../models/host/BetAmount';
import { Range } from '../modules/main/slice/player';

export const isNewGameType = (GameType: GameTypeEnum): boolean => {
    switch (GameType) {
        case GameTypeEnum.AndarBahar:
        case GameTypeEnum.Baccarat:
        case GameTypeEnum.Blackjack:
        case GameTypeEnum.Dragon:
        case GameTypeEnum.PokDeng:
        case GameTypeEnum.SeDie:
        case GameTypeEnum.Roulette:
        case GameTypeEnum.TeenPatti2020:
        case GameTypeEnum.SicBo:
            return false;
    }
    return true;
};
export const getIsRestState = (
    restMode: TableMode,
    isTest: boolean
): boolean => {
    if (restMode == TableMode.Close) {
        return true;
    } else if (restMode == TableMode.InternalTest) {
        if (isTest) {
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
};
export const replaceDefaultTable = (search: string, table: string) => {
    let str = search;
    let param = 'defaulttable=' + table;
    if (table == 'lobby') {
        param = '';
    }
    if (str.indexOf('options=defaulttable=') >= 0) {
        const pattern1: RegExp = new RegExp(
            'options=defaulttable=([^&]*)',
            'i'
        );
        str = str.replace(pattern1, 'options=' + param);
    } else if (str.indexOf('options=&') >= 0) {
        str = str.replace('options=&', 'options=' + param + '&');
    } else if (str.indexOf(',defaulttable=') >= 0) {
        const pattern1: RegExp = new RegExp(',defaulttable=([^&]*)', 'i');
        str = str.replace(pattern1, ',' + param);
    } else if (str.indexOf('options=') >= 0) {
        const pattern1: RegExp = new RegExp('options=([^&]*)', 'i');
        str = str.replace(pattern1, 'options=' + param + ',');
    } else {
        str += '&options=' + param;
    }
    return str;
};
export const gotoNewUI = (
    username: string | null,
    table: string = ''
): void => {
    try {
        window.localStorage.setItem(username + '_ui_version', 'new');
        let ui = window.localStorage.getItem('fullParameter');
        if (table !== '' && ui) {
            ui = replaceDefaultTable(ui, table);
        }
        if (ui) {
            const search = new URLSearchParams(ui);
            const token = search.get('token');
            if (token) {
                const newToken = token.substring(0, token.length - 1) + '1';
                ui = updateParam(ui, 'token', newToken);
            }
        }
        // let canBackParam = '&back=true';
        // if (ui && ui.indexOf(canBackParam) > 0) {
        //     canBackParam = ''; //not add if exist;
        // }
        const new_ui_domain =
            window.location.origin.replace('://ws.', '://ws2.') + ROUTE_ROOT;
        if (window.location.href.indexOf('://ws.') > 0) {
            window.location.href = new_ui_domain + ui; // + canBackParam;
        } else {
            window.location.href = new_ui_domain.replace('1234', '1235') + ui; // + canBackParam;
        }
    } catch (e) {
        console.error(e);
    }
};

export const mergeCurrencyName = (
    serverCurrencyName: string | undefined
): string => {
    if (!serverCurrencyName) return '';
    const num = serverCurrencyName.match(/\d+/g);
    const letr = serverCurrencyName.match(/[a-zA-Z]+/g);
    if (!letr) return serverCurrencyName;
    return num && num[0] == '2' ? letr[0] : serverCurrencyName;
};
export const displayCurrencyName = (
    showCurrencyName: CommonConfigType['showCurrencyName'],
    serCurrencyName: string,
    serLobbyCode: string
): boolean => {
    let isShowCurrencyName = true;
    if (showCurrencyName) {
        for (const cData of showCurrencyName) {
            let exCurrency = cData['exCurrency'];
            let include = cData['include'];
            let exclude = cData['exclude'];
            let isCurrency = exCurrency.includes(serCurrencyName);
            if (exCurrency[0] === 'all') isCurrency = true;
            if (isCurrency) {
                if (exclude[0] === 'all') {
                    isShowCurrencyName = false;
                    break;
                } else if (serLobbyCode) {
                    if (include) {
                        if (
                            include.includes(serLobbyCode.toLowerCase()) ===
                            false
                        ) {
                            isShowCurrencyName = false;
                            break;
                        }
                    } else if (exclude) {
                        if (exclude.includes(serLobbyCode.toLowerCase())) {
                            isShowCurrencyName = false;
                            break;
                        }
                    }
                }
            }
        }
    }

    return isShowCurrencyName;
};

export const numberFormat = (
    input: number,
    cc: number = 2,
    dd?: string,
    tt?: string
): string => {
    //let n = input;
    let n: string;
    let c = isNaN((cc = Math.abs(cc))) ? 2 : cc;
    let d = dd == undefined ? '.' : dd;
    let t = tt == undefined ? ',' : tt;
    let s = input < 0 ? '-' : '';
    let i: string = parseInt((n = Math.abs(+input || 0).toFixed(c))) + '';
    let jj = i.length;
    let j = jj > 3 ? jj % 3 : 0;
    return (
        s +
        (j ? i.substr(0, j) + t : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
        (c
            ? d +
              Math.abs(Number(n) - Number(i))
                  .toFixed(c)
                  .slice(2)
            : '')
    );
};

export const calcLowHigh = (range: Range): number => {
    const { Low, High } = range;
    let low = Low ?? 0,
        high = High ?? 0;
    let negative: boolean = false;
    let sign: number = 1 << 31;
    if ((high & sign) != 0) {
        negative = true;
        //low = ~low;
        low = ~low;
        high = ~high;
    }
    let result: number = high * 0x100000000 + low;
    if (negative) result = -(result + 1);
    return result;
};

export const getLoginToken = (
    oldToken: string,
    reconnectKey: string
): string => {
    if (!reconnectKey || reconnectKey == '') {
        return oldToken;
    }
    // return md5(oldToken + reconnectKey).toUpperCase();
    const newToken = md5(oldToken + reconnectKey).toUpperCase();
    return (
        newToken.substring(0, newToken.length - 1) +
        oldToken[oldToken.length - 1]
    );
};

export const updateLocalStorageLanguage = (lang: string): void => {
    const pattern: RegExp = new RegExp('lang=([^&]*)', 'i');
    let loginParam = window.localStorage.getItem(LocalStorageKey.FullParameter);
    if (loginParam) {
        loginParam = loginParam.replace(pattern, 'lang=' + lang);
        window.localStorage.setItem(LocalStorageKey.FullParameter, loginParam);
    }

    console.log('app::login::save', lang);
};

export const updateLocalStorageReconnectKey = (newToken: string): void => {
    let loginParam = window.localStorage.getItem(LocalStorageKey.FullParameter);
    if (loginParam) {
        loginParam = updateParam(loginParam, 'token', newToken);
        window.localStorage.setItem(LocalStorageKey.FullParameter, loginParam);
    }
    console.log('app::login::save', newToken);
};
export const updateParam = (
    str: string,
    key: string,
    value: string
): string => {
    const pattern: RegExp = new RegExp(`${key}=([^&]*)`, 'i');
    return str.replace(pattern, `${key}=${value}`);
};
export const ExchangeBetAmountByCurrency = (
    betAmount: number,
    rate: number,
    exchangeRate: number
) => {
    if (rate === exchangeRate) {
        return betAmount;
    }
    return Math.ceil((betAmount * rate) / exchangeRate / 100) * 100;
};

export const ExchangeBetAmount = (
    betAmount: number | undefined,
    exchangeRate: number
): number => {
    if (!betAmount) betAmount = 0;
    if (exchangeRate != 100000000) {
        if (exchangeRate < 999999 && exchangeRate >= 100) {
            exchangeRate = Math.floor(exchangeRate / 100) * 100;
        }
        betAmount =
            Math.ceil((betAmount * 100000000) / exchangeRate / 100) * 100;
    } else {
        betAmount = Math.ceil(betAmount / 100) * 100;
    }
    return betAmount;
};

export const getBetSummary = (
    BetAmount: BetPlayerAmount | undefined,
    exchangeRate: number,
    isRest: boolean = false
): string => {
    let b = '0',
        bpc = 0;
    if (BetAmount && isRest === false) {
        b = numberFormat(
            ExchangeBetAmount(BetAmount.Amount, exchangeRate) / 100,
            0
        );
        bpc = BetAmount.BetPlayerCount;
    }
    return `${b}/${bpc}`;
};

export const sleep = async (ms: number) => {
    return new Promise(resolve => {
        const sleepId = setTimeout(() => {
            resolve(sleepId);
        }, ms);
    });
};

export const updateRemainTime = (value: number) => {
    let _countDownFinishTime: number = moment().valueOf();
    if (value !== 0) {
        _countDownFinishTime = moment().valueOf() + (value - 1) * 1000;
    }
    return _countDownFinishTime;
};

export const windowClose = () => {
    window.close();
};

export const getShoeRoundByGameCount = (gameCount: number): ShoeRound => ({
    Shoe: Math.floor(gameCount / 10000),
    Round: gameCount % 10000,
});

export const getPokerByIndex = (i?: number): PokerCard | undefined => {
    if (i === undefined) {
        return undefined;
    }
    let text = getPokerText(i);
    let suit = getPokerSuit(i);

    return { text, suit };
};
const getPokerText = (i: number): string => {
    const n = i % 13;
    switch (n) {
        case 0:
            return 'A';
        case 10:
            return 'J';
        case 11:
            return 'Q';
        case 12:
            return 'K';
        default:
            return (n + 1).toString();
    }
};
const getPokerSuit = (i: number): Suit => {
    switch (true) {
        case i < 13:
            return Suit.SPADE;
        case i < 26:
            return Suit.HEART;
        case i < 39:
            return Suit.CLUB;
        case i < 52:
            return Suit.DIAMOND;
        default:
            return Suit.NONE;
    }
};

export type CheckBetsProp = {
    hostId: number;
    bets: Array<Bet>;
    amountOverBetLimit?: (
        betType: number,
        hostId: number,
        chipAmount: number,
        confirmedBets?: Array<Bet>
    ) => number;
    totalPendingBetAmount: number;
    totalPendingWithHoldAmount: number;
    availableBalance: number;
    disableBetTypes?: Array<number>;
    withHold?: Array<Bet>;
    confirmedBets?: Array<Bet>;
};
export type AvailableBetsProp = {
    bets: Array<Bet>;
    isAllIn: boolean;
    haveBetLimitAllIn: boolean;
    isOutOfBetLimit: boolean;
    isNotEnoughMoney: boolean;
    haveDisabledBetType: boolean;
};
export const checkBetsAvailed = (props: CheckBetsProp): AvailableBetsProp => {
    let isAllIn: boolean = false;
    let haveBetLimitAllIn = false;
    let isOutOfBetLimit = false;
    let isNotEnoughMoney = false;
    let haveDisabledBetType = false;
    let newBets = new Array<Bet>();

    let currentPendingBetAmount = 0;
    let currentWithHoldAmount = 0;
    for (let bet of props.bets) {
        if (props.disableBetTypes) {
            if (props.disableBetTypes.indexOf(bet.Type) >= 0) {
                haveDisabledBetType = true;
                continue;
            }
        }
        // check over bet limit
        let amount = bet.Amount;
        if (props.amountOverBetLimit) {
            const overLimit = props.amountOverBetLimit(
                bet.Type,
                props.hostId,
                amount,
                props.confirmedBets
            );
            if (overLimit > 0) {
                if (amount <= overLimit) {
                    isOutOfBetLimit = true;
                    break;
                } else {
                    amount -= overLimit;
                    haveBetLimitAllIn = true;
                }
            }

            let pendingBetAmount = amount;
            let withHoldRate = 0;
            if (props.withHold) {
                const tmp = props.withHold.find(lr => lr.Type === bet.Type);
                if (tmp) {
                    withHoldRate = tmp.Amount;
                }
            }
            if (
                props.totalPendingBetAmount +
                    props.totalPendingWithHoldAmount +
                    currentPendingBetAmount +
                    currentWithHoldAmount +
                    pendingBetAmount +
                    pendingBetAmount * withHoldRate >
                props.availableBalance * 100
            ) {
                pendingBetAmount =
                    Math.floor(props.availableBalance) * 100 -
                    props.totalPendingBetAmount -
                    props.totalPendingWithHoldAmount -
                    currentPendingBetAmount -
                    currentWithHoldAmount;
                if (withHoldRate > 0 && pendingBetAmount > 0) {
                    pendingBetAmount =
                        Math.floor(
                            pendingBetAmount / (withHoldRate + 1) / 100
                        ) * 100;
                }
                if (pendingBetAmount <= 0) {
                    isNotEnoughMoney = true;
                    break;
                }
                isAllIn = true;
            }
            let newBet = { ...bet };
            newBet.Amount = pendingBetAmount;
            newBets.push(newBet);
            currentPendingBetAmount += pendingBetAmount;
            currentWithHoldAmount += pendingBetAmount * withHoldRate;
        }
    }
    return {
        bets: newBets,
        isAllIn: isAllIn,
        haveBetLimitAllIn: haveBetLimitAllIn,
        isOutOfBetLimit: isOutOfBetLimit,
        isNotEnoughMoney: isNotEnoughMoney,
        haveDisabledBetType: haveDisabledBetType,
    };
};
