export enum GameType {
    None = 0,
    Baccarat = 1 << 0,
    Dragon = 1 << 1,
    SicBo = 1 << 2,
    FanTan = 1 << 3,
    Roulette = 1 << 4,
    SlotGame = 1 << 5,
    Lotto48 = 1 << 6,
    MiniGame = 1 << 7,
    LotterySideBet = 1 << 8,
    Blackjack = 1 << 9,
    AnimatedGame = 1 << 10,
    P2PGame = 1 << 11,
    SqueezeBaccarat = 1 << 12,
    MoneyWheel = 1 << 13,
    PokDeng = 1 << 14,
    AndarBahar = 1 << 15,
    TeenPatti2020 = 1 << 16,
    SeDie = 1 << 17,
    CarnivalTreasure = 1 << 18,
    ThaiHiLo = 1 << 19,
    FishPrawnCrab = 1 << 20,
}
