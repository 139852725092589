import {
    ArrowBackIos as ArrowBackIosIcon,
    ArrowDropDown as ArrowDropDownIcon,
    Check,
} from '@mui/icons-material';
import {
    Box,
    Button,
    Grid,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';
import BaseImage from '../../../components/common/baseImage';
import { AudioPlayContext } from '../../../contexts/AudioPlayContext';
import { CommonConfigContext } from '../../../contexts/ConfigContext';
import { VideoPlayerContext } from '../../../contexts/VideoContext';
import { WebSocketContext } from '../../../contexts/WebSocketContext';
import { ROUTE_LOBBY } from '../../../models/Route';
import { CMDPsRuleChange } from '../../../models/cmd/live';
import { RootState } from '../../../store/store';
import { limitRedFormatter } from '../../../utils/formatter';
import { getHostById } from '../../host/slice';
import { getMainLimitRedState, getMainUserState } from '../../main/selector';
import { LimitRed } from '../../main/slice/limitRed';
import { routeSliceActions } from '../../routeController/slice';
import { Setting } from '../../setting';
import UserInformation from '../../setting/components/userInformation';

type HeaderProp = {
    isMultiBet: boolean;
};
export const PGameHeader = (props: HeaderProp) => {
    const { hostId } = useContext(VideoPlayerContext);
    const { audioStop } = useContext(AudioPlayContext);
    const { hideBackToLobby } = useContext(CommonConfigContext);
    const { isMultiBet } = props;
    const dispatch = useDispatch();
    const handerStr = isMultiBet ? 'system.multibet' : `hostList.${hostId}`;
    const { IsInternalTest } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );

    const { t } = useTranslation();
    const handleOnClick = () => {
        audioStop();
        dispatch(routeSliceActions.goto(ROUTE_LOBBY));
    };
    return (
        <>
            <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
                flexWrap="nowrap"
                alignItems="center"
                sx={{ height: '50px', backgroundColor: 'black' }}
            >
                {hideBackToLobby ? (
                    <Box width="15px"></Box>
                ) : (
                    <Button
                        aria-label="back to lobby"
                        onClick={() => handleOnClick()}
                        sx={{
                            borderRight: '2px solid rgba(255, 255, 255, 0.11)',
                        }}
                    >
                        <ArrowBackIosIcon
                            sx={{ fontSize: '32px', color: '#ffffff' }}
                        />
                    </Button>
                )}
                <Grid
                    item
                    container
                    direction={'column'}
                    lineHeight={1.1}
                    sx={{ fontSize: '18px' }}
                >
                    {IsInternalTest ? '(INT)' : ''}
                    {t(handerStr)}
                    <Grid item fontSize={'18px'}>
                        <BettingLimitSelector />
                    </Grid>
                </Grid>
                <Grid item>
                    <UserInformation isHeader={true} isInGame={true} />
                </Grid>
                <Setting />
            </Grid>
            {isMultiBet && (
                <Grid
                    sx={{
                        display: 'flex',
                    }}
                >
                    <Grid
                        sx={{
                            backgroundColor: '#000',
                            paddingRight: '10px',
                            paddingLeft: '10px',
                            color: '#967e5a',
                        }}
                    >
                        {t(`hostList.${hostId}`)}
                    </Grid>
                </Grid>
            )}
        </>
    );
};

const BettingLimitSelector = () => {
    const { hostId } = useContext(VideoPlayerContext);
    const { playButtonAudio } = useContext(AudioPlayContext);
    const { sendCommand } = useContext(WebSocketContext);
    const [selectedLimitRed, setSelectedLimitRed] = useState<LimitRed>();
    const limitRedByGameType = useSelector(getMainLimitRedState);
    const { CurrencyName } = useSelector(getMainUserState);
    const host = useSelector((state: RootState) => getHostById(state, hostId));
    const [limitRed, setLimitRed] = useState<Array<LimitRed>>([]);
    const id = 'limit-red-selector';
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [menuItems, setMenuItems] = useState<Array<JSX.Element>>([]);

    useEffect(() => {
        const limitRedByGame = limitRedByGameType.BetRuleByGameType?.find(
            l => l.GameType === host?.GameType
        );
        const BetRule = limitRedByGame?.BetRule;
        if (limitRedByGame && BetRule) {
            let selBetRule: LimitRed;
            if (limitRedByGame.RuleToSelect) {
                selBetRule = limitRedByGame.RuleToSelect;
                submitBetRule(selBetRule);
            } else {
                const last = BetRule.find(r => r.Selected === 1);
                if (last) {
                    selBetRule = last;
                } else {
                    selBetRule = BetRule[0];
                    submitBetRule(selBetRule);
                }
            }
            batch(() => {
                setSelectedLimitRed(selBetRule);
                setLimitRed(BetRule);
            });
        }
    }, [hostId, limitRedByGameType]); //handle switch table
    useEffect(() => {
        const cells = new Array<JSX.Element>();
        let index = 0;
        for (const lr of limitRed) {
            const betLimit = limitRedFormatter(lr, CurrencyName);
            const cell = (
                <MenuItem
                    onClick={() => handleChangeLimitRed(lr)}
                    key={`bet-limit-selector-${index}`}
                >
                    <ListItemIcon>
                        {selectedLimitRed === lr && <Check fontSize="small" />}
                    </ListItemIcon>
                    <ListItemText>{betLimit}</ListItemText>
                </MenuItem>
            );
            cells.push(cell);
            index++;
        }
        setMenuItems(cells);
    }, [limitRed, CurrencyName, selectedLimitRed]);
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        if (limitRed.length > 1) {
            setAnchorEl(event.currentTarget);
        }
    };
    const submitBetRule = (lr: LimitRed) => {
        const cmd = new CMDPsRuleChange();
        cmd.GameType = host.GameType;
        cmd.HostID = hostId;
        cmd.RuleIndex = lr.RuleID;
        sendCommand(cmd);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleChangeLimitRed = (lr: LimitRed) => {
        playButtonAudio();
        if (host) {
            setSelectedLimitRed(lr);
            submitBetRule(lr);
            handleClose();
        }
    };
    return (
        <>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                disableElevation
                disableRipple
                onClick={e => {
                    handleOpen(e);
                    playButtonAudio();
                }}
                sx={{
                    height: '18px',
                    gap: '2px',
                    color: '#fff',
                    fontSize: '18px',
                    padding: '0px',
                    '& div': {
                        marginRight: '6px',
                    },
                }}
            >
                <BaseImage className="commonUI header_bet_limit" scale={0.5} />
                {selectedLimitRed &&
                    limitRedFormatter(selectedLimitRed, CurrencyName)}
                {limitRed.length > 1 && (
                    <ArrowDropDownIcon
                        sx={{
                            color: '#ffffff',
                            width: '25px',
                            marginLeft: '6px',
                        }}
                    />
                )}
            </Button>
            <Menu
                id={id}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                sx={{
                    marginLeft: '-35px',
                }}
            >
                {menuItems}
            </Menu>
        </>
    );
};
