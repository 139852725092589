import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import {
    Currency,
    UserState,
    userSliceActions,
} from '../../../modules/main/slice/user';
// import { cmsDataSliceActions } from '../../../modules/main/slice/cmsData';
import { batch } from 'react-redux';
import {
    betPlayersSliceActions,
    getNewPlayer,
} from '../../../modules/bet/players/slice';
import { resultProgressSliceActions } from '../../../modules/progress/slice/result';
import { progressTimeActions } from '../../../modules/progress/slice/time';
import { getLoginToken } from '../../../utils/commonFunc';
import { Progress } from '../../games/Progress';
import { CMDSpLogin, ICommand } from '../live';
import { AbstractHandler } from './AbstractHandler';

export class CMDSpLoginHandler extends AbstractHandler {
    _command: CMDSpLogin;
    _token: string;
    constructor(command: ICommand, token: string) {
        super();
        this._command = command as CMDSpLogin;
        this._token = token;
    }
    handle(dispatch: Dispatch<AnyAction>): void {
        const loginCommand = this._command;
        console.log('command::handler::login', loginCommand);
        const data = {} as UserState;
        data.AllowLoginSource = loginCommand.AllowLoginSource;
        data.CurrencyList = loginCommand.CurrencyList.map(
            c =>
                ({
                    CurrencyType: c.CurrencyType,
                    ExchangeRate: c.ExchangeRate,
                }) as Currency
        );
        data.CurrencyName = loginCommand.CurrencyName;
        data.CurrencyType = loginCommand.CurrencyType;
        data.ExchangeRate = 0;
        for (const c of data.CurrencyList) {
            if (c.CurrencyType === data.CurrencyType) {
                data.ExchangeRate = c.ExchangeRate;
                break;
            }
        }
        data.DuplicateLogin = loginCommand.DuplicateLogin === 1;
        data.ErrorMsgPack = loginCommand.ErrorMsgPack;
        data.FPlayerPrefs = loginCommand.FPlayerPrefs.toString();
        data.FPlayerPrefs2 = loginCommand.FPlayerPrefs2.toString();
        data.FPlayerPrefs3 = loginCommand.FPlayerPrefs3.toString();
        data.FPlayerPrefs4 = loginCommand.FPlayerPrefs4.toString();
        data.IsCommonWallet = loginCommand.IsCommonWallet === 1;
        data.LastLogin = loginCommand.LastLogin;
        data.LobbyCode = loginCommand.LobbyCode;
        data.LobbyType = loginCommand.LobbyType;
        data.MinimumToken = loginCommand.MinimumToken;
        data.NoChanceJackpot = loginCommand.NoChanceJackpot;
        data.PlayerID = loginCommand.PlayerID;
        data.PlayerPrefs = loginCommand.PlayerPrefs;
        data.PlayerPrefs2 = loginCommand.PlayerPrefs2;
        data.PlayerPrefs3 = loginCommand.PlayerPrefs3;
        data.PlayerPrefs4 = loginCommand.PlayerPrefs4;
        data.LoginToken = getLoginToken(this._token, loginCommand.ReconnectKey);
        data.UNIXTimestamp = loginCommand.UNIXTimestamp;
        data.LoginTime = Date.now().valueOf(); // moment().valueOf();

        data.Username = loginCommand.Username;
        data.VideoToken = loginCommand.VideoToken;
        data.IsTrial = loginCommand.IsTrial == 1;
        data.IsDemo = loginCommand.LobbyCode === 'eventdemo';
        data.IsInternalTest = loginCommand.InternalTestMode == 1;
        const newPlayer = getNewPlayer(loginCommand.PlayerID);
        newPlayer.name = loginCommand.Username;
        batch(() => {
            dispatch(
                progressTimeActions.set({
                    key: 'launcher',
                    time: loginCommand.LoginLaucherUTCTime,
                })
            );
            dispatch(userSliceActions.updateData(data));
            //dispatch(cmsDataSliceActions.loadCmsData(loginCommand.LobbyCode));
            dispatch(
                resultProgressSliceActions.endLoad(
                    Progress.SERVER_USER_INFO_RESULT
                )
            );
            dispatch(betPlayersSliceActions.add(newPlayer));
        });

        const time_change = window.localStorage.getItem('next_login_time');
        if (time_change) {
            dispatch(userSliceActions.updateNowTime(Number(time_change)));
            window.localStorage.removeItem('next_login_time');
        }
    }
}
