import { Box, Grid, Stack } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { VideoPlayerContext } from '../../../../../../contexts/VideoContext';
import { useBalance } from '../../../../../../hooks/useBalance';
import { GameState as GameStateEnum } from '../../../../../../models/games/enums/GameState';
import { Bet } from '../../../../../../models/host/BetAmount';
import { StateMsgType } from '../../../../../../models/Popup';
import { RootState } from '../../../../../../store/store';
import { checkBetsAvailed } from '../../../../../../utils/commonFunc';
import {
    getCurrentGameId,
    getGameSate,
    getTotalPendingBetsAmount,
    getTotalPendingWithHoldAmount,
    getWithHold,
    isReBetOn,
} from '../../../../../games/selector';
import { gameSliceActions } from '../../../../../games/slice';
import { getHostById } from '../../../../../host/slice';
import { inGamePopupSliceActions } from '../../../../../popup/inGameSlice';
import { ChipList } from '../../Baccarat/ChipList';
import { ChipSelector } from '../../Baccarat/ChipSelector';
import { DoubleBetButton } from '../../Baccarat/DoubleBetButton';
import { ReBetButton } from '../../Baccarat/ReBetButton';
import { amountOverBetLimit } from '../utils';
import { PokDengBetArea } from './PokDengBetArea';

export const getChipListMask = (k: string | undefined): string => {
    if (k === 'c') {
        return '12,15,19'; //#0c0f13
    }
    return getTableBetAreaColor(k);
};

export const getTableBetAreaColor = (k: string | undefined): string => {
    switch (k) {
        case 'd':
            return '122,35,20'; //#7a2314
        case 'p':
            return '121,79,177'; //794fb1
        case 'e':
        case 'm':
            return '3,66,31'; //03421f
        case 'a':
            return '100,62,64'; //643E40
        case 'c':
            return '53,68,87'; //354457
        default:
            return '27,57,27'; //1b391b
    }
};

export const BetAreaPanel = () => {
    const { hostId } = useContext(VideoPlayerContext);
    const { IsRest, CurrentState, ResultReleased, GameType } = useSelector(
        (state: RootState) => getHostById(state, hostId)
    );
    const { Group } = useSelector((state: RootState) =>
        getHostById(state, hostId)
    );
    const [tableColor, setTableColor] = useState<string>('');
    const [isShowReBet, setIsShowReBet] = useState<boolean>(false);
    const reBetButtonEnable = useSelector((state: RootState) =>
        isReBetOn(state)
    );
    const [isBetting, setIsBetting] = useState<boolean>(false);
    const [doubleBets, setDoubleBets] = useState<Array<Bet>>([]);

    const { doubleBet, reBet, prevBets, pendingBets, confirmedBets } =
        useSelector(getGameSate);
    useEffect(() => {
        const bets = new Array<Bet>();
        for (const cb of confirmedBets) {
            bets.push({ Type: cb.Type, Amount: cb.Amount, GameId: cb.GameId });
        }
        for (const pb of pendingBets) {
            const index = bets.findIndex(cb => cb.Type === pb.Type);
            if (index === -1) bets.push(pb);
            else bets[index].Amount += pb.Amount;
        }
        setDoubleBets(bets);
    }, [pendingBets, confirmedBets]);

    const addingBet = useRef(false);
    const [toAddBets, setToAddBets] = useState<Array<Bet>>();
    const allPendingBetsAmount = useSelector((state: RootState) =>
        getTotalPendingBetsAmount(state)
    );
    const allPendingWithHoldAmount = useSelector((state: RootState) =>
        getTotalPendingWithHoldAmount(state)
    );
    const withHold = useSelector((state: RootState) => getWithHold(state));
    const { availableBalance } = useBalance();
    const currentGameId = useSelector((state: RootState) =>
        getCurrentGameId(state)
    );
    const dispatch = useDispatch();
    const doPreBet = (bets: Array<Bet>) => {
        batch(() => {
            bets.forEach(bet => {
                dispatch(
                    gameSliceActions.onPendingBet({
                        GameId: currentGameId,
                        Type: bet.Type,
                        Amount: bet.Amount,
                    })
                );
            });
        });
    };
    useEffect(() => {
        setIsBetting(IsRest ? false : CurrentState === GameStateEnum.Betting);
    }, [IsRest, CurrentState, ResultReleased]);

    useEffect(() => {
        setIsShowReBet(isBetting ? reBetButtonEnable : false);
    }, [reBetButtonEnable, isBetting]);

    useEffect(() => {
        if (Group) {
            setTableColor(getTableBetAreaColor(Group[0]));
        }
    }, [Group]);
    useEffect(() => {
        if (doubleBet) {
            if (doubleBets && !toAddBets && !addingBet.current) {
                addingBet.current = true;
                setToAddBets(doubleBets);
            }
            dispatch(gameSliceActions.onDoubleBet({ onClick: false }));
        }
    }, [doubleBet, doubleBets]);
    useEffect(() => {
        if (reBet) {
            if (prevBets && !toAddBets && !addingBet.current) {
                addingBet.current = true;
                setToAddBets(prevBets);
            }
            dispatch(gameSliceActions.onReBet({ onClick: false }));
        }
    }, [reBet, prevBets]);
    useEffect(() => {
        if (toAddBets) {
            const availableBets = checkBetsAvailed({
                hostId: hostId,
                bets: toAddBets,
                amountOverBetLimit: amountOverBetLimit,
                totalPendingBetAmount: allPendingBetsAmount,
                totalPendingWithHoldAmount: allPendingWithHoldAmount,
                availableBalance: availableBalance,
                withHold: withHold,
            });
            if (availableBets.isNotEnoughMoney) {
                dispatch(
                    inGamePopupSliceActions.open(
                        'system.not_enough_money',
                        GameType,
                        StateMsgType.betInfo_Fail,
                        currentGameId
                    )
                );
            } else if (availableBets.isOutOfBetLimit) {
                dispatch(
                    inGamePopupSliceActions.open(
                        'system.bet_out_of_limit_red',
                        GameType,
                        StateMsgType.betInfo_Fail,
                        currentGameId
                    )
                );
            } else {
                const toBets = availableBets.bets;
                const betTypes = toBets.map((bet: Bet) => ({
                    type: bet.Type,
                }));
                const lastBetType: number = betTypes.length - 1;
                doPreBet(toBets);
                if (availableBets.haveDisabledBetType) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.have_cannot_rebet_zone',
                            GameType,
                            StateMsgType.betInfo_Warning,
                            currentGameId
                        )
                    );
                } else if (availableBets.haveBetLimitAllIn) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.bet_exceed_limit_allin',
                            GameType,
                            StateMsgType.betInfo_Warning,
                            currentGameId
                        )
                    );
                } else if (availableBets.isAllIn) {
                    dispatch(
                        inGamePopupSliceActions.open(
                            'system.all_in',
                            GameType,
                            StateMsgType.betInfo_Warning,
                            currentGameId
                        )
                    );
                }
                dispatch(
                    gameSliceActions.setSelectedBetType(betTypes[lastBetType])
                );
            }
            setToAddBets(undefined);
            addingBet.current = false;
        }
    }, [toAddBets]);
    return (
        <>
            <Stack
                sx={{
                    marginTop: '324px', //52+272//52=header,272=video
                    width: '540px',
                    height: '372px',
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        width: '540px',
                        height: '372px',
                        zIndex: -2,
                        background: `rgba(${tableColor})`,
                    }}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        width: '540px',
                        height: '372px',
                        zIndex: -1,
                        background: `rgba(${tableColor})`,
                        // background:
                        //     'linear-gradient(to bottom, #12301A, #1A4022, #13321B)',
                    }}
                />

                <Grid
                    item
                    container
                    sx={{
                        paddingTop: '4px',
                    }}
                >
                    <PokDengBetArea tableColor={`rgba(${tableColor})`} />
                    <Grid
                        item
                        container
                        sx={{
                            paddingTop: '3px',
                            paddingLeft: '5px',
                            paddingRight: '5px',
                            marginBottom: '1px',
                            zIndex: 1,
                        }}
                    >
                        <ChipSelector />
                        <ChipList />
                        {isShowReBet ? <ReBetButton /> : <DoubleBetButton />}
                    </Grid>
                </Grid>
            </Stack>
        </>
    );
};
