import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDFishprawncrabStatistics implements ICommand
{

	public FishCount : number = 0;
	public CalabashCount : number = 0;
	public PrawnCount : number = 0;
	public CrabCount : number = 0;
	public TigerCount : number = 0;
	public RoosterCount : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.FishCount = ba.readUint8();
		this.CalabashCount = ba.readUint8();
		this.PrawnCount = ba.readUint8();
		this.CrabCount = ba.readUint8();
		this.TigerCount = ba.readUint8();
		this.RoosterCount = ba.readUint8();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.FishCount);
		ba.writeUint8(this.CalabashCount);
		ba.writeUint8(this.PrawnCount);
		ba.writeUint8(this.CrabCount);
		ba.writeUint8(this.TigerCount);
		ba.writeUint8(this.RoosterCount);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDFishprawncrabStatistics();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'FishCount(B)='+this.FishCount+' ';
		str += 'CalabashCount(B)='+this.CalabashCount+' ';
		str += 'PrawnCount(B)='+this.PrawnCount+' ';
		str += 'CrabCount(B)='+this.CrabCount+' ';
		str += 'TigerCount(B)='+this.TigerCount+' ';
		str += 'RoosterCount(B)='+this.RoosterCount+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'FishprawncrabStatistics';
	}

}
