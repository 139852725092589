import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { LocalStorageKey } from '../../hooks/storage/useLocalStorage';
import { useQueryParams } from '../../hooks/useQueryParams';
import { Locale, getLocaleByCode } from '../../models/Locale';
import { ROUTE_GAME, ROUTE_LOBBY } from '../../models/Route';
import { Voice } from '../../models/Voice';
import { getGameSate } from '../games/selector';
import { LandingState, landingSliceActions } from '../main/slice/landing';
import { popupSliceActions } from '../popup/slice';
import { Progress } from '../progress';
import { progressTimeActions } from '../progress/slice/time';
import { SettingState } from '../setting/slice';
import { SOUND_KEY, soundSliceActions } from '../setting/slice/sound';
import { VOLUME_KEY, volumeSliceActions } from '../setting/slice/volume';

export const Landing = () => {
    const [inited, setInited] = useState<boolean>(false);
    const query = useQueryParams();
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const [entry, setEntry] = useState<string>();
    const gameState = useSelector(getGameSate);
    useEffect(() => {
        if (inited === true && entry)
            if (entry.startsWith(ROUTE_GAME))
                dispatch(progressTimeActions.mark('lobby'));
    }, [inited, entry]);
    useEffect(() => {
        if (inited) {
            return;
        }
        if (!query.ready) {
            return;
        }

        const element = document.getElementById('loading');
        element?.remove();

        let entry = ROUTE_LOBBY;
        const l = {} as LandingState;
        const token = query.values.get('token');
        if (token == null && !gameState.notToReconnect) {
            console.log('pop::login_fail' + gameState.notToReconnect);
            const reason = window.localStorage.getItem(
                LocalStorageKey.ClearReason
            );
            const display = reason ? `(${reason})` : '';
            dispatch(
                popupSliceActions.open(
                    ['popup.login_failed', display],
                    undefined,
                    undefined,
                    undefined,
                    true
                )
            );
            return;
        }
        l.token = token;
        l.lobby = query.values.get('lobby');
        l.username = query.values.get('username');
        l.lang = getLandingLocale(query.values.get('lang')) ?? Locale['en_US'];
        l.mobile = Boolean(query.values.get('mobile'));
        l.returnUrl = query.values.get('returnurl');
        l.ts = query.values.get('ts');
        // use options
        l.defaultTable = null;
        l.hideLogo = false;
        l.webView = false;
        l.lite = false;
        l.options = query.values.get('options');
        l.isSupportStorage = query.supportLocalStorage;
        l.showNewUI = true;
        const referer = query.values.get('referer');
        l.referer = referer ? referer : '';

        l.options?.split(',').map(p => {
            const [k, param] = p.split('=');
            if (k === 'defaulttable') {
                const hostId = Number(param);
                l.defaultTable = hostId || null;
                entry = `${ROUTE_GAME}/${hostId}`;
            } else if (k === 'hidelogo') {
                l.hideLogo = param === '1';
            } else if (k === 'webview') {
                l.webView = param === '1';
            } else if (k === 'lite') {
                l.lite = param === '1';
            }
        });
        l.isReconnect = query.values.get('isReconnect') === '1';
        if (l.lang) i18n.changeLanguage(l.lang.long);
        // load user setting from local storage
        const json = window.localStorage.getItem('user.setting');
        if (json) {
            const userSetting = JSON.parse(json) as SettingState;
            console.log('app::setting::init', userSetting);
            //CR240425 - remove CN 2 & ZH 2
            if (userSetting[SOUND_KEY].Voice2 === Voice.Cantonese2) {
                userSetting[SOUND_KEY] = {
                    ...userSetting[SOUND_KEY],
                    Voice2: Voice.Cantonese1,
                };
                window.localStorage.setItem(
                    'user.setting',
                    JSON.stringify(userSetting)
                );
            }
            if (userSetting[SOUND_KEY].Voice2 === Voice.Mandarin2) {
                userSetting[SOUND_KEY] = {
                    ...userSetting[SOUND_KEY],
                    Voice2: Voice.Mandarin1,
                };
                window.localStorage.setItem(
                    'user.setting',
                    JSON.stringify(userSetting)
                );
            }
            dispatch(volumeSliceActions.updateAll(userSetting[VOLUME_KEY]));
            dispatch(soundSliceActions.updateAll(userSetting[SOUND_KEY]));
        } else {
            dispatch(
                soundSliceActions.updateAll({
                    Voice2: getLocaleByCode('long', l.lang.long).voice,
                })
            );
        }
        console.log('app::main::landing', l, entry, json);
        dispatch(landingSliceActions.updateLanding(l));
        setEntry(entry);
        setInited(true);
    }, [query]);

    return <Progress loadEndRoute={entry} />;
};

const getLandingLocale = (code: string | null) => {
    if (code) {
        const long = Object.entries(Locale).find(
            l => l[0].toLowerCase() === code.toLowerCase()
        );
        const short = Object.values(Locale).find(l => l.short === code);
        return long?.[1] ?? short;
    }
    return Locale['en_US'];
};
