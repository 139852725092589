import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LobbyUiContext } from '../../../../contexts/LobbyUiContext';

export const NewGameLabel = () => {
    const { t } = useTranslation();
    return (
        <div className="new-game-cell-label-container">
            <div className="new-game-cell-label">
                <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 375 62"
                    fill="none"
                >
                    <path
                        d="M369 6.83331L6.85535 -3.8147e-06L6 54.6667L368.145 61.5L369 6.83331Z"
                        fill="#DDB70D"
                    />
                    <path
                        d="M0 9.74997L368.976 3.74997L375 51.75L6.0241 57.75L0 9.74997Z"
                        fill="url(#paint0_linear_23_227)"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_23_227"
                            x1="30.1205"
                            y1="74.9575"
                            x2="329.878"
                            y2="-76.5335"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#412000" />
                            <stop offset="1" stopColor="#825E01" />
                        </linearGradient>
                    </defs>
                </svg>

                <div className="new-game-cell-label-text">
                    {t('new_game.play_now')}
                </div>
            </div>
        </div>
    );

    // <div className="new-game-cell-label">{t('new_game.play_now')}</div>;
};
type Props = {
    gameType: number;
};
export const NewGameRoadMap = (props: Props) => {
    const { gameType } = props;
    const { grid } = useContext(LobbyUiContext);
    const isOneColumn = useMemo(() => grid === 12, [grid]);
    const containerClass = useMemo(
        () =>
            isOneColumn
                ? 'roulette-one-column-main-container'
                : 'roulette-two-column-main-container',
        [isOneColumn]
    );
    const roadMapBgSource = useMemo(
        () =>
            isOneColumn
                ? `${process.env.PUBLIC_URL}/resource/assets/image/new_ui/game_${gameType}.png`
                : `${process.env.PUBLIC_URL}/resource/assets/image/new_ui/game_small_${gameType}.png`,
        [isOneColumn, gameType]
    );

    return (
        <div className={containerClass}>
            {roadMapBgSource && (
                <img width="100%" height="100%" src={roadMapBgSource} />
            )}
            <NewGameLabel />
        </div>
    );
};
