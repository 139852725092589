import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    label: string;
    isChecked: boolean;
    setIsChecked: Dispatch<SetStateAction<boolean>>;
    css: string;
};
const Checkbox = (props: Props) => {
    const { t } = useTranslation();
    const { label, isChecked, setIsChecked, css } = props;
    return (
        <div className={css}>
            <label>
                <span>{t(label)}</span>
                <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={() => setIsChecked(prev => !prev)}
                />
            </label>
        </div>
    );
};
export default Checkbox;
