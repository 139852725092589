import { GameType } from '../../models/games/enums/GameType';
import { GameCategoryTab } from '../../models/lobby/GameCategoryTab';

declare function gtag(...args: unknown[]): void;
export const sendGA = (gt: GameType, gct: GameCategoryTab | -1) => {
    const en = getEventName(gt);
    const el = getEventLabel(gct);
    try {
        gtag('event', en, {
            category: 'engagement',
            label: el,
        });
    } catch (e) {
        console.warn('google::ga', e, { en, el });
    }
};

const getEventName = (gt: GameType) => {
    switch (gt) {
        case GameType.ThaiHiLo:
            return 'to_new_ui_thl';
        case GameType.CarnivalTreasure:
            return 'to_new_ui_ct';
        case GameType.FishPrawnCrab:
            return 'to_new_ui_fpc';
        default:
            return `to_new_ui_${gt}`;
    }
};
const getEventLabel = (gct: GameCategoryTab | -1) => {
    switch (gct) {
        case GameCategoryTab.Featured:
            return 'feature_page';
        case GameCategoryTab.Baccarat:
        case GameCategoryTab.OtherGames:
        case GameCategoryTab.MultiBet:
            return 'lobby_page';
        default:
            return 'switch_table';
    }
};
