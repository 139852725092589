import { EntityState, PayloadAction } from '@reduxjs/toolkit';
import { hostsAdapter } from '.';
import { BaseInformation } from '../../../models/games/BaseInformation';
import { GameState } from '../../../models/games/enums/GameState';
import { TableMode } from '../../../models/TableMode';
import { getIsRestState, updateRemainTime } from '../../../utils/commonFunc';
import { HostTable, SupportedGameResult } from '../models';
import {
    DealerLoginPayload,
    GameInitPayload,
    RestPayload,
    StartBetPayload,
    UpdateConfirmedBetsPayload,
    UpdateCountDownPayload,
    UpdateGameCountPayload,
    UpdateGameRestPayload,
    UpdateGameResultObjectPayload,
    UpdateGameResultPayload,
    UpdateGameStateByHostIdPayload,
    UpdateGoodRoadPayload,
    UpdateIsRestartPayload,
    UpdateIsRoundCancelPayload,
    UpdateMaxBetByGameTypePayload,
    UpdateMultiplierPayload,
    UpdatePokerShowPayload,
    UpdateRecordPayload,
    UpdateStatisticPayload,
    UpdateWinAmountPayload,
} from '../models/payloads';
// custom reducer
const dealerLogin = (
    state: EntityState<HostTable, number>,
    action: PayloadAction<DealerLoginPayload>
) =>
    hostsAdapter.updateOne(state, {
        id: action.payload.hostId,
        changes: {
            DealerList: action.payload.dealer,
        },
    });
const gameInit = (
    state: EntityState<HostTable, number>,
    action: PayloadAction<GameInitPayload>
) => {
    const game = action.payload.game;
    const goodRoad = game.GoodRoad;
    return hostsAdapter.updateOne(state, {
        id: action.payload.hostId,
        changes: {
            CurrentResult: game.CurrentResult,
            CurrentState: game.CurrentState,
            OldResult: game.OldResult,
            GoodRoad: goodRoad,
            IsRest: game.IsRest,
            IsInternalTest: game.IsInternalTest,
            IsGoodRoad:
                !game.IsRest &&
                goodRoad &&
                Object.values(goodRoad).includes(true),
            ConfirmedBets: game.ConfirmedBets,
            IsRoundCancel: false,
            GameDelay: game.GameDelay,
        },
    });
};
const updateInformation = (
    state: EntityState<HostTable, number>,
    action: PayloadAction<BaseInformation>
) => {
    hostsAdapter.updateOne(state, {
        id: action.payload.HostId,
        changes: {
            AllowSideBet: action.payload.AllowSideBet,
            CountDown: action.payload.CountDown,
            //HallId: action.payload.HallId, use game_config
            HostId: action.payload.HostId,
            RemainTime: action.payload.RemainTime,
            Rest: action.payload.Rest,
            Seat: action.payload.Seat,
            TableLimit: action.payload.TableLimit,
        },
    });
};
const updateRecord = (
    state: EntityState<HostTable, number>,
    action: PayloadAction<UpdateRecordPayload>
) => {
    const hostId = action.payload.hostId;
    return hostsAdapter.updateOne(state, {
        id: hostId,
        changes: {
            Record: action.payload.record,
        },
    });
};
const updateGameRest = (
    state: EntityState<HostTable, number>,
    action: PayloadAction<UpdateGameRestPayload>
) => {
    const isRest = getIsRestState(
        action.payload.onOrOff,
        action.payload.isTest
    ); //action.payload.onOrOff != 0;
    let changes: Partial<HostTable> = {
        IsRest: isRest,
        IsInternalTest:
            action.payload.isTest &&
            action.payload.onOrOff == TableMode.InternalTest,
    };
    if (isRest) {
        changes = {
            ...changes,
            CurrentState: GameState.Idle,
        };
    }
    return hostsAdapter.updateOne(state, {
        id: action.payload.hostId,
        changes,
    });
};
const updateGameResult = (
    state: EntityState<HostTable, number>,
    action: PayloadAction<UpdateGameResultPayload>
) => {
    const host = state.entities[action.payload.hostId];
    if (host) {
        const resultReleased =
            action.payload.resultReleased === undefined
                ? true //default true;
                : action.payload.resultReleased;
        const currentResult: SupportedGameResult =
            host.CurrentResult ?? ({} as SupportedGameResult);
        currentResult.ResultString = action.payload.resultString;
        currentResult.Result = action.payload.result;
        currentResult.FResult = action.payload.result.toString();
        return hostsAdapter.updateOne(state, {
            id: host.HostId,
            changes: {
                CurrentResult: currentResult,
                ResultReleased: resultReleased,
                ShuffleTimer: action.payload.shuffleTimer,
            },
        });
    }
};
const updateGameResultObject = (
    state: EntityState<HostTable, number>,
    action: PayloadAction<UpdateGameResultObjectPayload>
) =>
    hostsAdapter.updateOne(state, {
        id: action.payload.hostId,
        changes: {
            CurrentResult: action.payload.result,
        },
    });
const updateGameState = (
    state: EntityState<HostTable, number>,
    action: PayloadAction<UpdateGameStateByHostIdPayload>
) => {
    return hostsAdapter.updateOne(state, {
        id: action.payload.hostId,
        changes: {
            CurrentState: action.payload.state,
            ResultReleased: false,
        },
    });
};
const startBet = (
    state: EntityState<HostTable, number>,
    action: PayloadAction<StartBetPayload>
) => {
    const hostId = action.payload.hostId;
    const host = state.entities[hostId];
    if (host) {
        let record = host.Record;
        if (host.CurrentResult) {
            if (
                action.payload.result.GameCount <
                    host.CurrentResult.GameCount &&
                action.payload.result.GameCount < 10000
            ) {
                //non shoe game
                record = undefined;
            }
        }
        return hostsAdapter.updateOne(state, {
            id: hostId,
            changes: {
                RemainTime: action.payload.remainTime,
                CurrentResult: action.payload.result,
                ResultReleased: false,
                CurrentState: GameState.Betting,
                WinAmount: undefined,
                CountDown: action.payload.countDown
                    ? action.payload.countDown
                    : host.CountDown,
                PendingTime: action.payload.pendingTime
                    ? action.payload.pendingTime
                    : 0,
                Record: record,
                IsRoundCancel: false,
                CanLeave: action.payload.canLeave === true,
            },
        });
    }
};
const updateCountDown = (
    state: EntityState<HostTable, number>,
    action: PayloadAction<UpdateCountDownPayload>
) => {
    hostsAdapter.updateOne(state, {
        id: action.payload.hostId,
        changes: {
            RemainTime: updateRemainTime(action.payload.countDown / 1000),
            CountDown: action.payload.countDown,
            PendingTime: 0,
            CanLeave: action.payload.canLeave,
        },
    });
};
const updateGameCount = (
    state: EntityState<HostTable, number>,
    action: PayloadAction<UpdateGameCountPayload>
) =>
    hostsAdapter.updateOne(state, {
        id: action.payload.hostId,
        changes: {
            GameCount: action.payload.gameCount,
        },
    });
const updateStatistic = (
    state: EntityState<HostTable, number>,
    action: PayloadAction<UpdateStatisticPayload>
) =>
    hostsAdapter.updateOne(state, {
        id: action.payload.hostId,
        changes: {
            Statistics: action.payload.statistics,
        },
    });
const rest = (
    state: EntityState<HostTable, number>,
    action: PayloadAction<RestPayload>
) =>
    hostsAdapter.updateMany(
        state,
        action.payload.hostIds.map(id => ({
            id,
            changes: {
                IsRest: true,
                CurrentState: GameState.Idle,
            },
        }))
    );
const updateMaxBetByGameType = (
    state: EntityState<HostTable, number>,
    action: PayloadAction<UpdateMaxBetByGameTypePayload>
) => {
    const hostIds = new Array<number>();
    for (const h of Object.values(state.entities)) {
        if (h && h.GameType === action.payload.gameType) hostIds.push(h.HostId);
    }
    return hostsAdapter.updateMany(
        state,
        hostIds.map(id => ({
            id,
            changes: {
                MaxBet: action.payload.maxBet,
            },
        }))
    );
};
const updatePokerShow = (
    state: EntityState<HostTable, number>,
    action: PayloadAction<UpdatePokerShowPayload>
) =>
    hostsAdapter.updateOne(state, {
        id: action.payload.hostId,
        changes: {
            PokerShowResult: action.payload.results,
        },
    });
const updateWinAmount = (
    state: EntityState<HostTable, number>,
    action: PayloadAction<UpdateWinAmountPayload>
) =>
    hostsAdapter.updateOne(state, {
        id: action.payload.hostId,
        changes: {
            WinAmount: action.payload.amount,
        },
    });

const updateGoodRoad = (
    state: EntityState<HostTable, number>,
    action: PayloadAction<UpdateGoodRoadPayload>
) => {
    const host = state.entities[action.payload.hostId];
    if (host) {
        const goodRoad = action.payload.goodRoad;
        return hostsAdapter.updateOne(state, {
            id: host.HostId,
            changes: {
                GoodRoad: goodRoad,
                IsGoodRoad: goodRoad && Object.values(goodRoad).includes(true),
            },
        });
    }
};
const updateConfirmedBets = (
    state: EntityState<HostTable, number>,
    action: PayloadAction<UpdateConfirmedBetsPayload>
) => {
    const host = state.entities[action.payload.hostId];
    if (host) {
        return hostsAdapter.updateOne(state, {
            id: host.HostId,
            changes: {
                ConfirmedBets: action.payload.bets,
            },
        });
    }
};
//CMDScGameResultHandler & useInGamePopup
const updateIsRestart = (
    state: EntityState<HostTable, number>,
    action: PayloadAction<UpdateIsRestartPayload>
) => {
    return hostsAdapter.updateOne(state, {
        id: action.payload.hostId,
        changes: {
            IsRestart: action.payload.isRestart,
        },
    });
};
const updateIsRoundCancel = (
    state: EntityState<HostTable, number>,
    action: PayloadAction<UpdateIsRoundCancelPayload>
) => {
    return hostsAdapter.updateOne(state, {
        id: action.payload.hostId,
        changes: {
            IsRoundCancel: action.payload.isRoundCancel,
        },
    });
};
const updateMultiplier = (
    state: EntityState<HostTable, number>,
    action: PayloadAction<UpdateMultiplierPayload>
) => {
    return hostsAdapter.updateOne(state, {
        id: action.payload.hostId,
        changes: {
            Multiplier: action.payload.multiplier,
        },
    });
};
export const hostsReducers = {
    dealerLogin,
    gameInit,
    updateInformation,
    updateRecord,
    updateGameRest,
    updateGameResult,
    updateGameResultObject,
    updateGameState,
    startBet,
    updateGameCount,
    updateStatistic,
    rest,
    updateMaxBetByGameType,
    updatePokerShow,
    updateWinAmount,
    updateGoodRoad,
    updateConfirmedBets,
    updateCountDown,
    updateIsRestart,
    updateIsRoundCancel,
    updateMultiplier,
};
