import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDPsBetLogFailQuery implements ICommand
{

	public FromDate : string = "";
	public ToDate : string = "";

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.FromDate = ba.readString();
		this.ToDate = ba.readString();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeString(this.FromDate);
		ba.writeString(this.ToDate);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDPsBetLogFailQuery();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'FromDate(S)='+this.FromDate+' ';
		str += 'ToDate(S)='+this.ToDate+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 55100;
	}

	public getCmdName() : string
	{
		return 'PsBetLogFailQuery';
	}

}
