import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDCarnivaltreasureStatistics implements ICommand
{

	public Main1Count : number = 0;
	public Main2Count : number = 0;
	public Main4Count : number = 0;
	public Main7Count : number = 0;
	public Main18Count : number = 0;
	public Main40Count : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Main1Count = ba.readUint8();
		this.Main2Count = ba.readUint8();
		this.Main4Count = ba.readUint8();
		this.Main7Count = ba.readUint8();
		this.Main18Count = ba.readUint8();
		this.Main40Count = ba.readUint8();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.Main1Count);
		ba.writeUint8(this.Main2Count);
		ba.writeUint8(this.Main4Count);
		ba.writeUint8(this.Main7Count);
		ba.writeUint8(this.Main18Count);
		ba.writeUint8(this.Main40Count);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDCarnivaltreasureStatistics();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Main1Count(B)='+this.Main1Count+' ';
		str += 'Main2Count(B)='+this.Main2Count+' ';
		str += 'Main4Count(B)='+this.Main4Count+' ';
		str += 'Main7Count(B)='+this.Main7Count+' ';
		str += 'Main18Count(B)='+this.Main18Count+' ';
		str += 'Main40Count(B)='+this.Main40Count+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'CarnivaltreasureStatistics';
	}

}
