import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDHsFishprawncrabResult implements ICommand
{

	public Dice1 : number = 0;
	public Dice2 : number = 0;
	public Dice3 : number = 0;

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.Dice1 = ba.readUint8();
		this.Dice2 = ba.readUint8();
		this.Dice3 = ba.readUint8();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint8(this.Dice1);
		ba.writeUint8(this.Dice2);
		ba.writeUint8(this.Dice3);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDHsFishprawncrabResult();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Dice1(B)='+this.Dice1+' ';
		str += 'Dice2(B)='+this.Dice2+' ';
		str += 'Dice3(B)='+this.Dice3+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 0;
	}

	public getCmdName() : string
	{
		return 'HsFishprawncrabResult';
	}

}
