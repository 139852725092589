export enum POPUP_TYPE {
    ALERT, // only confirm button
    CONFIRM, // confirm & cancel button
    POPUP, // no button
}

export enum POPUP_CONFIRM_ACTION {
    NONE,
    RETRY,
    NEW_SIDE_BET,
}

export enum StateMsgType {
    betInfo_Normal = 0,
    betInfo_Success = 1,
    betInfo_Fail = 2,
    betResult_Player = 3,
    betResult_Banker = 4,
    betResult_Tie = 5,
    betResult_Dragon = 6,
    betResult_Tiger = 7,
    betInfo_Warning = 8,
    betResult = 9,
    betResult_Andar,
    betResult_Bahar,
    betResult_BJ_win,
    betResult_BJ_lose,
    betResult_SmallOdd,
    betResult_SmallEven,
    betResult_BigOdd,
    betResult_BigEven,
    betResult_Even,
}
