import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDPsClientUiChange implements ICommand
{

	public UIType : string = "";
	public currenttoken : string = "";

	public constructor()
	{
	}

	public fromBytes(ba : ByteArray) : void
	{
		this.UIType = ba.readString();
		this.currenttoken = ba.readString();
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeString(this.UIType);
		ba.writeString(this.currenttoken);

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDPsClientUiChange();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'UIType(S)='+this.UIType+' ';
		str += 'currenttoken(S)='+this.currenttoken+' ';
		return str;
	}

	public getCmdID() : number
	{
		return 55103;
	}

	public getCmdName() : string
	{
		return 'PsClientUiChange';
	}

}
