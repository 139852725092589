import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AndarBaharTotalBet } from '../../../../../models/games/andarBahar';
import { BaccaratTotalBet } from '../../../../../models/games/baccarat';
import { DragonTigerTotalBet } from '../../../../../models/games/dragonTiger';
import { GameType as GameTypeEnum } from '../../../../../models/games/enums/GameType';
import { PokDengTotalBet } from '../../../../../models/games/pokdeng';
import { RouletteTotalBet } from '../../../../../models/games/roulette';
import { SedieTotalBet } from '../../../../../models/games/sedie';
import { SicboTotalBet } from '../../../../../models/games/sicbo';
import { TeenPattiTotalBet } from '../../../../../models/games/teenpatti';
import { RootState } from '../../../../../store/store';
import { getBetSummary } from '../../../../../utils/commonFunc';
import { getHostById } from '../../../../host/slice';
import { getMainUserState } from '../../../../main/selector';
import { CommonTotalBet, getTotalBets } from '../../../../totalBet/slice';
import { ROAD_MAP_COLORS } from '../../../constants';
type Props = {
    hostId: number;
};
type TextType = {
    text: string;
    color: string;
};
const DEFAULT_TEXT = {
    text: '',
    color: ROAD_MAP_COLORS.TRANSPARENT,
};
const DEFAULT_BET_AMOUNT_STATISTIC = '0/0';
export const BetAmountStatistic = ({ hostId }: Props) => {
    const host = useSelector((state: RootState) => getHostById(state, hostId));
    const { ExchangeRate } = useSelector(getMainUserState);
    const { t } = useTranslation();
    const { IsRest, GameType } = host;
    const { TotalBet } = useSelector((state: RootState) =>
        getTotalBets(state, hostId)
    );
    const [betAmount1, setBetAmount1] = useState(DEFAULT_BET_AMOUNT_STATISTIC);
    const [betAmount2, setBetAmount2] = useState(DEFAULT_BET_AMOUNT_STATISTIC);
    const [betAmount3, setBetAmount3] = useState(DEFAULT_BET_AMOUNT_STATISTIC);
    const [betAmount4, setBetAmount4] = useState(DEFAULT_BET_AMOUNT_STATISTIC);
    const [betAmount5, setBetAmount5] = useState(DEFAULT_BET_AMOUNT_STATISTIC);

    const [text1, setText1] = useState<TextType>(DEFAULT_TEXT);
    const [text2, setText2] = useState<TextType>(DEFAULT_TEXT);
    const [text3, setText3] = useState<TextType>(DEFAULT_TEXT);
    const [text4, setText4] = useState<TextType>(DEFAULT_TEXT);
    const [text5, setText5] = useState<TextType>(DEFAULT_TEXT);

    const [needType3, setNeedType3] = useState(true);
    const [needType4, setNeedType4] = useState(false);
    const [needType5, setNeedType5] = useState(false);
    const [newGame, setNewGame] = useState(false);

    useEffect(() => {
        if (GameType == GameTypeEnum.AndarBahar) {
            setNeedType3(false);
        }
        if (
            GameType == GameTypeEnum.SeDie ||
            GameType == GameTypeEnum.PokDeng ||
            GameType == GameTypeEnum.Roulette
        ) {
            setNeedType4(true);
        }
        if (GameType == GameTypeEnum.PokDeng) {
            setNeedType5(true);
        }
    }, [GameType]);

    useEffect(() => {
        let b1 = DEFAULT_BET_AMOUNT_STATISTIC,
            b2 = DEFAULT_BET_AMOUNT_STATISTIC,
            b3 = DEFAULT_BET_AMOUNT_STATISTIC,
            b4 = DEFAULT_BET_AMOUNT_STATISTIC,
            b5 = DEFAULT_BET_AMOUNT_STATISTIC;
        if (TotalBet) {
            let BetAmount = TotalBet as CommonTotalBet;
            switch (GameType) {
                case GameTypeEnum.Baccarat:
                    BetAmount = TotalBet as BaccaratTotalBet;
                    b1 = getBetSummary(BetAmount.Banker, ExchangeRate, IsRest);
                    b2 = getBetSummary(BetAmount.Player, ExchangeRate, IsRest);
                    b3 = getBetSummary(BetAmount.Tie, ExchangeRate, IsRest);
                    break;
                case GameTypeEnum.PokDeng:
                    BetAmount = TotalBet as PokDengTotalBet;
                    b1 = getBetSummary(BetAmount.Player1, ExchangeRate, IsRest);
                    b2 = getBetSummary(BetAmount.Player2, ExchangeRate, IsRest);
                    b3 = getBetSummary(BetAmount.Player3, ExchangeRate, IsRest);
                    b4 = getBetSummary(BetAmount.Player4, ExchangeRate, IsRest);
                    b5 = getBetSummary(BetAmount.Player5, ExchangeRate, IsRest);
                    break;
                case GameTypeEnum.SeDie:
                    BetAmount = TotalBet as SedieTotalBet;
                    b1 = getBetSummary(BetAmount.Small, ExchangeRate, IsRest);
                    b2 = getBetSummary(BetAmount.Big, ExchangeRate, IsRest);
                    b3 = getBetSummary(BetAmount.Odd, ExchangeRate, IsRest);
                    b4 = getBetSummary(BetAmount.Even, ExchangeRate, IsRest);
                    break;
                case GameTypeEnum.Dragon:
                    BetAmount = TotalBet as DragonTigerTotalBet;
                    b1 = getBetSummary(BetAmount.Dragon, ExchangeRate, IsRest);
                    b2 = getBetSummary(BetAmount.Tiger, ExchangeRate, IsRest);
                    b3 = getBetSummary(BetAmount.Tie, ExchangeRate, IsRest);
                    break;
                case GameTypeEnum.AndarBahar:
                    BetAmount = TotalBet as AndarBaharTotalBet;
                    b1 = getBetSummary(BetAmount.Andar, ExchangeRate, IsRest);
                    b2 = getBetSummary(BetAmount.Bahar, ExchangeRate, IsRest);
                    break;
                case GameTypeEnum.SicBo:
                    BetAmount = TotalBet as SicboTotalBet;
                    b1 = getBetSummary(BetAmount.Big, ExchangeRate, IsRest);
                    b2 = getBetSummary(BetAmount.Small, ExchangeRate, IsRest);
                    b3 = getBetSummary(
                        BetAmount.AllTriple,
                        ExchangeRate,
                        IsRest
                    );
                    break;
                case GameTypeEnum.TeenPatti2020:
                    BetAmount = TotalBet as TeenPattiTotalBet;
                    b1 = getBetSummary(
                        BetAmount.PlayerAWin,
                        ExchangeRate,
                        IsRest
                    );
                    b2 = getBetSummary(
                        BetAmount.PlayerBWin,
                        ExchangeRate,
                        IsRest
                    );
                    b3 = getBetSummary(BetAmount.Tie, ExchangeRate, IsRest);
                    break;
                case GameTypeEnum.Roulette:
                    BetAmount = TotalBet as RouletteTotalBet;
                    b1 = getBetSummary(BetAmount.Red, ExchangeRate, IsRest);
                    b2 = getBetSummary(BetAmount.Black, ExchangeRate, IsRest);
                    b3 = getBetSummary(BetAmount.Even, ExchangeRate, IsRest);
                    b4 = getBetSummary(BetAmount.Odd, ExchangeRate, IsRest);
                    break;
                default:
                    setNewGame(true);
                    break;
            }
        }

        setBetAmount1(b1);
        setBetAmount2(b2);
        needType3 && setBetAmount3(b3);
        needType4 && setBetAmount4(b4);
        needType5 && setBetAmount5(b5);
    }, [TotalBet]);

    useEffect(() => {
        if (GameType) {
            const text1 = {} as TextType;
            const text2 = {} as TextType;
            const text3 = {} as TextType;
            const text4 = {} as TextType;
            const text5 = {} as TextType;
            switch (GameType) {
                // Baccarat use default
                case GameTypeEnum.Baccarat:
                    text1.text = 'road.banker_win';
                    text1.color = ROAD_MAP_COLORS.RED;
                    setText1(text1);
                    text2.text = 'road.player_win';
                    text2.color = ROAD_MAP_COLORS.BLUE;
                    setText2(text2);
                    text3.text = 'road.draw';
                    text3.color = ROAD_MAP_COLORS.GREEN;
                    setText3(text3);
                    break;
                case GameTypeEnum.Dragon:
                    text1.text = 'road.dragon_win';
                    text1.color = ROAD_MAP_COLORS.BLUE;
                    setText1(text1);
                    text2.text = 'road.tiger_win';
                    text2.color = ROAD_MAP_COLORS.RED;
                    setText2(text2);
                    text3.text = 'road.draw';
                    text3.color = ROAD_MAP_COLORS.GREEN;
                    setText3(text3);
                    break;
                case GameTypeEnum.SeDie:
                    text1.text = 'road.small';
                    text1.color = ROAD_MAP_COLORS.BLUE;
                    setText1(text1);
                    text2.text = 'road.big';
                    text2.color = ROAD_MAP_COLORS.RED;
                    setText2(text2);
                    text3.text = 'road.odd';
                    text3.color = ROAD_MAP_COLORS.BLUE;
                    setText3(text3);
                    text4.text = 'road.even';
                    text4.color = ROAD_MAP_COLORS.RED;
                    setText4(text4);
                    break;
                case GameTypeEnum.AndarBahar:
                    text1.text = 'A';
                    text1.color = ROAD_MAP_COLORS.BLUE;
                    setText1(text1);
                    text2.text = 'B';
                    text2.color = ROAD_MAP_COLORS.RED;
                    setText2(text2);
                    break;
                case GameTypeEnum.PokDeng:
                    text1.text = 'pokdeng.player.0';
                    text1.color = ROAD_MAP_COLORS.LIGHT_BLUE;
                    setText1(text1);
                    text2.text = 'pokdeng.player.1';
                    text2.color = ROAD_MAP_COLORS.LIGHT_BLUE;
                    setText2(text2);
                    text3.text = 'pokdeng.player.2';
                    text3.color = ROAD_MAP_COLORS.LIGHT_BLUE;
                    setText3(text3);
                    text4.text = 'pokdeng.player.3';
                    text4.color = ROAD_MAP_COLORS.LIGHT_BLUE;
                    setText4(text4);
                    text5.text = 'pokdeng.player.4';
                    text5.color = ROAD_MAP_COLORS.LIGHT_BLUE;
                    setText5(text5);
                    break;
                case GameTypeEnum.SicBo:
                    text1.text = 'road.big';
                    text1.color = ROAD_MAP_COLORS.RED;
                    setText1(text1);
                    text2.text = 'road.small';
                    text2.color = ROAD_MAP_COLORS.BLUE;
                    setText2(text2);
                    text3.text = 'road.dice_triple';
                    text3.color = ROAD_MAP_COLORS.GREEN;
                    setText3(text3);
                    break;
                case GameTypeEnum.TeenPatti2020:
                    text1.text = 'A';
                    text1.color = ROAD_MAP_COLORS.BLUE;
                    setText1(text1);
                    text2.text = 'B';
                    text2.color = ROAD_MAP_COLORS.RED;
                    setText2(text2);
                    text3.text = 'road.draw';
                    text3.color = ROAD_MAP_COLORS.GREEN;
                    setText3(text3);
                    break;
                case GameTypeEnum.Roulette:
                    text1.text = 'road.red';
                    text1.color = ROAD_MAP_COLORS.RED;
                    setText1(text1);
                    text2.text = 'road.black';
                    text2.color = ROAD_MAP_COLORS.BLACK;
                    setText2(text2);
                    text3.text = 'road.even';
                    text3.color = ROAD_MAP_COLORS.RED;
                    setText3(text3);
                    text4.text = 'road.odd';
                    text4.color = ROAD_MAP_COLORS.BLUE;
                    setText4(text4);
                    break;
            }
        }
    }, [GameType]);

    return newGame ? (
        <></>
    ) : (
        <>
            <Stack
                direction={'row'}
                spacing={1.5}
                sx={{
                    margin: '0 16px',
                    height: '100%',
                    display: 'flex',
                    overflowX: 'scroll',
                    whiteSpace: 'nowrap',
                    scrollBehavior: 'smooth',
                    scrollbarWidth: 'none',
                    '&::-webkit-scrollbar': {
                        display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                    },
                    '&-ms-overflow-style': {
                        display: 'none', // Hide the scrollbar for IE
                    },
                }}
            >
                <SvgInfo
                    fill={text1.color}
                    text={t(text1.text)}
                    value={betAmount1}
                />
                <SvgInfo
                    fill={text2.color}
                    text={t(text2.text)}
                    value={betAmount2}
                />
                {needType3 && (
                    <SvgInfo
                        fill={text3.color}
                        text={t(text3.text)}
                        value={betAmount3}
                    />
                )}
                {needType4 && (
                    <SvgInfo
                        fill={text4.color}
                        text={t(text4.text)}
                        value={betAmount4}
                    />
                )}
                {needType5 && (
                    <SvgInfo
                        fill={text5.color}
                        text={t(text5.text)}
                        value={betAmount5}
                    />
                )}
            </Stack>
        </>
    );
};
type SvgInfoProps = {
    fill: string;
    text: string;
    value: string;
};
const SvgInfo = ({ fill, text, value }: SvgInfoProps) => {
    return (
        <>
            <Stack direction={'row'} spacing={1}>
                <Box
                    sx={{
                        minWidth: '24px',
                        maxHeight: '24px',
                        backgroundColor: fill,
                        borderRadius: '12px',
                    }}
                >
                    <Typography
                        fontSize={18}
                        sx={{
                            minWidth: '24px',
                            width: 'fit-content',
                            textAlign: 'center',
                            padding: '0 4px',
                        }}
                    >
                        {text}
                    </Typography>
                    {/*<svg width="fit-content" height="24">
                        <circle cx="12" cy="12" r="12" fill={fill}></circle>
                        <text
                            x="12"
                            y="14"
                            textAnchor="middle"
                            dominantBaseline="middle"
                            fill="#ffffff"
                            fontSize="18"
                        >
                            {text}
                        </text>
                    </svg>*/}
                </Box>
                <Typography sx={{ alignSelf: 'center' }}>{value}</Typography>
            </Stack>
        </>
    );
};
