import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
    Box,
    List,
    ListItem,
    ListItemText,
    Stack,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BetState } from '../../../../models/games/enums/BetState';
import { GameType as GameTypeEnum } from '../../../../models/games/enums/GameType';
import { RootState } from '../../../../store/store';
import { amountFormatter } from '../../../../utils/formatter';
import { REPORT_COLORS, REPORT_SIZE } from '../../../lobby/constants';
import i18n from '../../../locales/i18n';
import { getMainUserState } from '../../../main/selector';
import { Bet } from '../../../report/slice/betRecord';
import { DetailListDivider } from '../ReportDivider';
import { UsernameGrid } from '../UsernameGrid';
import { BaccGameResultRecordPanel } from './BaccGameResultRecordPanel';
import { BlackjackDetailContentRemark } from './BlackjackDetailContentRemark';
import { BlackjackResultRecordPanel } from './BlackjackResultRecordPanel';
import { DragonTigerResultRecordPanel } from './DragonTigerResultRecordPanel';
import { PokDengGameResultRecordPanel } from './PokDengGameResultRecordPanel';
import { RouletteResultRecordPanel } from './RouletteGameResultRecordPanel';
import { SedieGameResultRecordPanel } from './SedieGameResultRecordPanel';
import { SicBoResultRecordPanel } from './SicBoResultRecordPanel';
import {
    TeenPattiResultDetailPanel,
    TeenPattiResultRecordPanel,
} from './TeenPattiResultRecordPanel';
import { AndarBaharResultDetailPanel } from './andarBahar/AndarBaharResultDetailPanel';
import { AndarBaharResultRecordPanel } from './andarBahar/AndarBaharResultRecordPanel';
import { FishPrawnCrabResultRecordPanel } from './FishPrawnCrabResultRecordPanel';

type DetailProps = {
    onGoing: boolean;
    bet: Bet;
    gameName: string;
    username: string;
};
export const Detail = (props: DetailProps) => {
    const { IsCommonWallet } = useSelector((state: RootState) =>
        getMainUserState(state)
    );
    //GameType.AndarBahar & GameType.TeenPatti2020 個樣唔同
    const { onGoing, bet, gameName, username } = props;
    const { t, i18n } = useTranslation();
    const [languageDir, setLanguageDir] = useState<string>('ltr');
    useEffect(() => {
        setLanguageDir(i18n.dir(i18n.language));
    }, [i18n.language]);

    const [showResultDetail, setShowResultDetail] = useState<boolean>(false);
    const gameRoundItem = () => {
        const getRoundText = () => {
            switch (bet.GameType) {
                case GameTypeEnum.SeDie:
                case GameTypeEnum.TeenPatti2020:
                case GameTypeEnum.AndarBahar:
                case GameTypeEnum.Roulette:
                case GameTypeEnum.SicBo:
                case GameTypeEnum.ThaiHiLo:
                case GameTypeEnum.FishPrawnCrab:
                    return (
                        <>
                            {languageDir == 'rtl'
                                ? `${bet.Round} / ${gameName}`
                                : `${gameName} / ${bet.Round}`}
                        </>
                    );
                default:
                    return (
                        <>
                            {languageDir == 'rtl'
                                ? `${bet.Shoes}-${bet.Round} / ${gameName}`
                                : `${gameName} / ${bet.Shoes}-${bet.Round}`}
                        </>
                    );
            }
        };

        return (
            <>
                <DetailListItem
                    titleId={`${t('betlog.game')} / ${t('betlog.round')}`}
                >
                    <Box
                        sx={{
                            direction: 'ltr',
                        }}
                    >
                        {getRoundText()}
                    </Box>
                </DetailListItem>
                <DetailListDivider />
            </>
        );
    };

    const startBalanceItem = () => {
        return IsCommonWallet ? (
            <></>
        ) : (
            <>
                <DetailListItem
                    titleId={t('betlog.start_balance')}
                    setLangDirection={false}
                >
                    {onGoing ? (
                        <></>
                    ) : (
                        <>
                            {amountFormatter(
                                (bet.Balance - bet.ResultAmount) / 100,
                                false,
                                REPORT_SIZE.TEXT
                            )}
                        </>
                    )}
                </DetailListItem>
                <DetailListDivider />
            </>
        );
    };

    const balanceItem = () => {
        return IsCommonWallet ? (
            <></>
        ) : (
            <>
                <DetailListItem
                    titleId={t('betlog.balance')}
                    setLangDirection={false}
                >
                    {onGoing ? (
                        <></>
                    ) : (
                        <>
                            {amountFormatter(
                                bet.Balance / 100,
                                false,
                                REPORT_SIZE.TEXT
                            )}
                        </>
                    )}
                </DetailListItem>
                <DetailListDivider />
            </>
        );
    };

    const resultItem = () => {
        const getResultPanel = () => {
            console.log('test-1', bet.GameType);
            switch (bet.GameType) {
                case GameTypeEnum.Baccarat:
                case GameTypeEnum.SqueezeBaccarat:
                    return <BaccGameResultRecordPanel bet={bet} />;
                case GameTypeEnum.SeDie:
                    return <SedieGameResultRecordPanel bet={bet} />;
                case GameTypeEnum.PokDeng:
                    return <PokDengGameResultRecordPanel bet={bet} />;
                case GameTypeEnum.Dragon:
                    return <DragonTigerResultRecordPanel bet={bet} />;
                case GameTypeEnum.SicBo:
                case GameTypeEnum.ThaiHiLo:
                    return <SicBoResultRecordPanel bet={bet} />;
                case GameTypeEnum.TeenPatti2020:
                    return <TeenPattiResultRecordPanel bet={bet} />;
                case GameTypeEnum.AndarBahar:
                    return <AndarBaharResultRecordPanel bet={bet} />;
                case GameTypeEnum.Roulette:
                    return <RouletteResultRecordPanel bet={bet} />;
                case GameTypeEnum.Blackjack:
                    return <BlackjackResultRecordPanel bet={bet} />;
                case GameTypeEnum.FishPrawnCrab:
                    return <FishPrawnCrabResultRecordPanel bet={bet} />;
                default:
                    return <></>;
            }
        };

        const getResultDetailPanel = () => {
            switch (bet.GameType) {
                case GameTypeEnum.TeenPatti2020:
                    return <TeenPattiResultDetailPanel bet={bet} />;
                case GameTypeEnum.AndarBahar:
                    return <AndarBaharResultDetailPanel bet={bet} />;
                default:
                    return <></>;
            }
        };

        const getHeight = () => {
            switch (bet.GameType) {
                case GameTypeEnum.Baccarat:
                case GameTypeEnum.SqueezeBaccarat:
                case GameTypeEnum.Dragon:
                case GameTypeEnum.TeenPatti2020:
                case GameTypeEnum.PokDeng:
                case GameTypeEnum.AndarBahar:
                    return '106px';
                default:
                    return '15vw';
            }
        };

        const showDetailToggleButton = () => {
            switch (bet.GameType) {
                case GameTypeEnum.TeenPatti2020:
                case GameTypeEnum.AndarBahar:
                    return onGoing === false;
                default:
                    return false;
            }
        };

        const onShowResultDetail = () => {
            showDetailToggleButton() && setShowResultDetail(!showResultDetail);
        };

        return (
            <>
                <DetailListItem
                    titleId={t('betlog.result')}
                    height={getHeight()}
                    onClick={onGoing ? undefined : onShowResultDetail}
                    setLangDirection={false}
                    iconButton={
                        showDetailToggleButton() ? (
                            <svg width={24} height={24}>
                                <>
                                    <circle
                                        cx={12}
                                        cy={12}
                                        r={12}
                                        fill={REPORT_COLORS.TEXT}
                                    />
                                    {showResultDetail ? (
                                        <ExpandLess sx={{ color: '#000' }} />
                                    ) : (
                                        <ExpandMore sx={{ color: '#000' }} />
                                    )}
                                </>
                            </svg>
                        ) : (
                            <></>
                        )
                    }
                >
                    {onGoing ? <>{t('betlog.on_going')}</> : getResultPanel()}
                </DetailListItem>
                {showResultDetail && (
                    <Box
                        sx={{
                            direction: 'ltr',
                        }}
                    >
                        {getResultDetailPanel()}
                    </Box>
                )}
                <DetailListDivider />
            </>
        );
    };

    return (
        <>
            <UsernameGrid username={username} />
            <Box overflow={'auto'}>
                <List>
                    <DetailListItem titleId={t('betlog.betId')}>
                        <>{bet.BetID}</>
                    </DetailListItem>
                    <DetailListDivider />
                    <DetailListItem
                        titleId={`${t('betlog.date')} / ${t('betlog.time')}`}
                    >
                        <>{`${bet.Date} / ${bet.Time}`}</>
                    </DetailListItem>
                    <DetailListDivider />
                    {gameRoundItem()}
                    {resultItem()}
                    <DetailListItem
                        titleId={`${t('betlog.content')} / ${t(
                            'betlog.remark'
                        )}`}
                    >
                        {bet.GameType === GameTypeEnum.Blackjack ? (
                            <BlackjackDetailContentRemark bet={bet} />
                        ) : (
                            <DetailContentRemark
                                gameType={bet.GameType}
                                betType={bet.BetType}
                                state={bet.State}
                            />
                        )}
                    </DetailListItem>
                    <DetailListDivider />
                    {startBalanceItem()}
                    <DetailListItem
                        titleId={t('betlog.bet_amount')}
                        setLangDirection={false}
                    >
                        <>
                            {amountFormatter(
                                bet.BetAmount / 100,
                                false,
                                REPORT_SIZE.TEXT
                            )}
                        </>
                    </DetailListItem>
                    <DetailListDivider />
                    <DetailListItem
                        titleId={t('betlog.win_lose')}
                        setLangDirection={false}
                    >
                        {onGoing ? (
                            <></>
                        ) : (
                            <>
                                {amountFormatter(
                                    bet.ResultAmount / 100,
                                    false,
                                    REPORT_SIZE.TEXT
                                )}
                            </>
                        )}
                    </DetailListItem>
                    <DetailListDivider />
                    {balanceItem()}
                </List>
            </Box>
        </>
    );
};
type DetailListItemProps = {
    titleId: string;
    height?: string;
    onClick?: () => void;
    iconButton?: JSX.Element;
    children: JSX.Element;
    setLangDirection?: boolean;
};
const DetailListItem = (props: DetailListItemProps) => {
    const {
        titleId,
        height = '15vw',
        onClick,
        iconButton,
        children,
        setLangDirection = true,
    } = props;
    return (
        <ListItem sx={{ height: height }} onClick={onClick}>
            <ListItemText>
                <Stack
                    direction={'row'}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Stack direction={'row'} alignItems="center" gap="5px">
                        <Typography
                            sx={{
                                color: REPORT_COLORS.TEXT,
                                fontSize: REPORT_SIZE.TEXT,
                            }}
                        >
                            {titleId}
                        </Typography>
                        {iconButton}
                    </Stack>
                    <Box
                        sx={{
                            fontSize: REPORT_SIZE.TEXT,
                            direction: `${setLangDirection ? i18n.dir(i18n.language) : ''}`,
                        }}
                    >
                        {children}
                    </Box>
                </Stack>
            </ListItemText>
        </ListItem>
    );
};

/**
 *  SA -> betLogItem.ts
 *     -> getContent
 *     -> getRemarkStr
 *  tips & gift, elno: 現時冇
 *
 *  */
const DetailContentRemark = ({
    betType,
    gameType,
    state,
}: {
    betType: number;
    gameType: number;
    state: number;
}) => {
    //TODO: other game
    const { t } = useTranslation();
    let txt: string[] = [];
    let contentTxt: string = '-';
    let remarkTxt: string = '';
    // content
    switch (gameType) {
        case GameTypeEnum.Baccarat:
        case GameTypeEnum.SqueezeBaccarat:
            contentTxt = t(`betlog.bet_bacc.${betType}`);
            break;
        case GameTypeEnum.SeDie:
            contentTxt = t(`betlog.bet_sedie.${betType}`);
            break;
        case GameTypeEnum.Dragon:
            contentTxt = t(`betlog.bet_dt.${betType}`);
            break;
        case GameTypeEnum.PokDeng:
            contentTxt = t(`betlog.bet_pokdeng.${betType}`);
            break;
        case GameTypeEnum.SicBo:
            contentTxt = t(`betlog.bet_sicbo.${betType}`);
            break;
        case GameTypeEnum.TeenPatti2020:
            contentTxt = t(`betlog.bet_teenpatti.${betType}`);
            break;
        case GameTypeEnum.AndarBahar:
            contentTxt = t(`betlog.bet_andarbahar.${betType}`);
            break;
        case GameTypeEnum.Roulette:
            contentTxt = t(`betlog.bet_roulette.${betType}`);
            break;
        case GameTypeEnum.ThaiHiLo:
            contentTxt = t(`betlog.bet_thaihilo.${betType}`);
            break;
        case GameTypeEnum.FishPrawnCrab:
            contentTxt = t(`betlog.bet_fishprawncrab.${betType}`);
            break;
    }
    txt.push(contentTxt);

    // remark
    switch (gameType) {
        default:
            switch (state) {
                case BetState.Won:
                case BetState.MWon:
                    remarkTxt = t('betlog.win');
                    break;
                case BetState.Lost:
                case BetState.MLost:
                    remarkTxt = t('betlog.lose');
                    break;
                case BetState.Refunded:
                case BetState.MRefunded:
                    remarkTxt = t('betlog.tie');
                    break;
            }
            txt.push(remarkTxt);
            break;
    }

    return <>{txt.join(' / ')}</>;
};
