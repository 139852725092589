import { CMDBetLogFailSummary } from "./CMDBetLogFailSummary"
import { ByteArray } from "../ByteArray"
import { ICommand } from "./ICommand"

export class CMDSpBetLogFailSummaryQuery implements ICommand
{

	public Bet : Array<CMDBetLogFailSummary>;

	public constructor()
	{
		this.Bet = new Array<CMDBetLogFailSummary>();
	}

	public fromBytes(ba : ByteArray) : void
	{
		let BetSize:number = ba.readUint32();
		while (BetSize-- > 0)
		{
			let BetChild:CMDBetLogFailSummary = new CMDBetLogFailSummary();
			BetChild.fromBytes(ba);
			this.Bet.push(BetChild);
		}
	}

	public toBytes() : ByteArray
	{
		let ba: ByteArray = new ByteArray();

		ba.writeUint32(this.Bet.length);
		for (const BetChild of this.Bet)
		{
			let tempBuf = BetChild.toBytes();
			ba.appendBuf(tempBuf.toBuffer());
		}

		return ba;
	}

	public clone() : ICommand
	{
		let cmd : ICommand = new CMDSpBetLogFailSummaryQuery();
		cmd.fromBytes(this.toBytes());

		return cmd;
	}

	public toString() : string
	{
		let str:string = '';
		str += 'Bet_size(D)='+this.Bet.length + ' ';
		str += 'Bet(CMDBetLogFailSummary)=[';
		for (let BetChild in this.Bet)
		{
			if (<number><unknown>BetChild > 0) str += ', ';
			str += this.Bet[BetChild].toString();
		}
		str += '] ';
		return str;
	}

	public getCmdID() : number
	{
		return 50132;
	}

	public getCmdName() : string
	{
		return 'SpBetLogFailSummaryQuery';
	}

}
